import { fromUnixTime, differenceInMinutes } from "date-fns";
import { Notyf } from "notyf";
import Bowser from "bowser";
import { useState } from "react";

const days = ["日", "月", "火", "水", "木", "金", "土"];

export const dateInFormat = (datetimeUnix: number) => {
  const date = fromUnixTime(datetimeUnix);

  return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日(${days[date.getDay()]})`;
};

export const dateInFormatEn = (datetimeUnix: number) => {
  const date = fromUnixTime(datetimeUnix);

  return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
};

export const dateOnlyInFormat = (datetimeUnix: number) => {
  const date = fromUnixTime(datetimeUnix);

  return `${date.getMonth() + 1}/${date.getDate()}`;
};

export const weekOnlyInFormat = (datetimeUnix: number) => {
  const date = fromUnixTime(datetimeUnix);

  return `${days[date.getDay()]}`;
};

export const timeInFormat = (datetimeUnix: number) => {
  const date = fromUnixTime(datetimeUnix);

  return `${date.getHours()}:${date.getMinutes() >= 10 ? date.getMinutes() : `0${date.getMinutes()}`}`;
};

export const timeZoneInFormat = (datetimeUnix: number) => {
  const date = fromUnixTime(datetimeUnix);
  const hour_diff = date.getTimezoneOffset() / 60;

  return hour_diff == -9 ? "日本時間" : `UTC ${hour_diff}:00`;
};

export const datetimeInFormat = (datetimeUnix: number) => `${dateInFormat(datetimeUnix)} ${timeInFormat(datetimeUnix)}`;

export const differenceInMinutesFromUnix = (endUnix: number, startUnix: number) =>
  differenceInMinutes(fromUnixTime(endUnix), fromUnixTime(startUnix));

export const useToast = (duration = 3000): Notyf => {
  const [toast] = useState<Notyf | null>(() => {
    if (global.document) {
      return new Notyf({
        duration,
        position: { x: "center", y: "top" },
      });
    }
    return null;
  });

  // サーバーサイドではnullですが、クライアントサイドでは存在します
  // メソッドの呼び出しはクライアントサイドでしかしないので、nullを削除します
  return toast as Notyf;
};

const browser =
  (typeof window !== "undefined" && window.navigator?.userAgent && Bowser.getParser(window.navigator.userAgent)) ||
  null;
export const useBrowser = () => browser;

const priceFormatter = new Intl.NumberFormat("ja-JP", {
  style: "currency",
  currency: "JPY",
  currencyDisplay: "name",
});

export const formatPrice = (price: number): string => priceFormatter.format(price);
