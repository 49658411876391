import { baseApi as api } from "../../store/baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAppSubscriptionPlan: build.query<GetAppSubscriptionPlanApiResponse, GetAppSubscriptionPlanApiArg>({
      query: () => ({ url: `/v1/app_subscription_plan` }),
    }),
    listEventArchives: build.query<ListEventArchivesApiResponse, ListEventArchivesApiArg>({
      query: (queryArg) => ({
        url: `/v1/event_archives`,
        params: { limit: queryArg.limit },
      }),
    }),
    listArchives: build.query<ListArchivesApiResponse, ListArchivesApiArg>({
      query: (queryArg) => ({
        url: `/v1/archives`,
        params: {
          pickup: queryArg.pickup,
          cursor: queryArg.cursor,
          limit: queryArg.limit,
        },
      }),
    }),
    getArchive: build.query<GetArchiveApiResponse, GetArchiveApiArg>({
      query: (queryArg) => ({ url: `/v1/archives/${queryArg.id}` }),
    }),
    listRecommendArchives: build.query<ListRecommendArchivesApiResponse, ListRecommendArchivesApiArg>({
      query: (queryArg) => ({
        url: `/v1/archives/${queryArg.id}/recommends`,
        params: { limit: queryArg.limit },
      }),
    }),
    watchArchive: build.mutation<WatchArchiveApiResponse, WatchArchiveApiArg>({
      query: (queryArg) => ({
        url: `/v1/archives/${queryArg.id}/watch`,
        method: "POST",
      }),
    }),
    resetPassword: build.mutation<ResetPasswordApiResponse, ResetPasswordApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/reset-password`,
        method: "POST",
        body: queryArg.resetPasswordInput,
      }),
    }),
    sendResetPasswordLink: build.mutation<SendResetPasswordLinkApiResponse, SendResetPasswordLinkApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/reset-password-link`,
        method: "POST",
        body: queryArg.sendResetPasswordLinkInput,
      }),
    }),
    signIn: build.mutation<SignInApiResponse, SignInApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/signin`,
        method: "POST",
        body: queryArg.signInInput,
      }),
    }),
    signInWithPartnerSchool: build.mutation<SignInWithPartnerSchoolApiResponse, SignInWithPartnerSchoolApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/signin/partner-school`,
        method: "POST",
        body: queryArg.signInWithPartnerSchoolInput,
      }),
    }),
    signUp: build.mutation<SignUpApiResponse, SignUpApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/signup`,
        method: "POST",
        body: queryArg.signUpInput,
      }),
    }),
    signUpWithProfessional: build.mutation<SignUpWithProfessionalApiResponse, SignUpWithProfessionalApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/signup/professional`,
        method: "POST",
        body: queryArg.signUpWithProfessionalInput,
      }),
    }),
    signUpWithPartnerSchool: build.mutation<SignUpWithPartnerSchoolApiResponse, SignUpWithPartnerSchoolApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/signup/partner-school`,
        method: "POST",
        body: queryArg.signUpWithPartnerSchoolInput,
      }),
    }),
    refreshAccessToken: build.mutation<RefreshAccessTokenApiResponse, RefreshAccessTokenApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/token`,
        method: "POST",
        body: queryArg.refreshAccessTokenInput,
      }),
    }),
    verifyEmail: build.mutation<VerifyEmailApiResponse, VerifyEmailApiArg>({
      query: (queryArg) => ({
        url: `/v1/auth/verify-email`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    listCategories: build.query<ListCategoriesApiResponse, ListCategoriesApiArg>({
      query: () => ({ url: `/v1/categories` }),
    }),
    listCategoryArchives: build.query<ListCategoryArchivesApiResponse, ListCategoryArchivesApiArg>({
      query: (queryArg) => ({
        url: `/v1/categories/${queryArg.id}/archives`,
        params: { cursor: queryArg.cursor, limit: queryArg.limit },
      }),
    }),
    verifyTrialCoupon: build.mutation<VerifyTrialCouponApiResponse, VerifyTrialCouponApiArg>({
      query: (queryArg) => ({
        url: `/v1/check_trial_coupon`,
        method: "POST",
        body: queryArg.verifyTrialCouponInput,
      }),
    }),
    listClassrooms: build.query<ListClassroomsApiResponse, ListClassroomsApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms`,
        params: { state: queryArg.state, userId: queryArg.userId },
      }),
    }),
    createClassroom: build.mutation<CreateClassroomApiResponse, CreateClassroomApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms`,
        method: "POST",
        body: queryArg.createClassroomInput,
      }),
    }),
    getClassroom: build.query<GetClassroomApiResponse, GetClassroomApiArg>({
      query: (queryArg) => ({ url: `/v1/classrooms/${queryArg.id}` }),
    }),
    updateClassroom: build.mutation<UpdateClassroomApiResponse, UpdateClassroomApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.updateClassroomInput,
      }),
    }),
    deleteClassroom: build.mutation<DeleteClassroomApiResponse, DeleteClassroomApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    listClassroomComments: build.query<ListClassroomCommentsApiResponse, ListClassroomCommentsApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/comments`,
        params: {
          cursor: queryArg.cursor,
          order: queryArg.order,
          limit: queryArg.limit,
        },
      }),
    }),
    createClassroomComment: build.mutation<CreateClassroomCommentApiResponse, CreateClassroomCommentApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/comments`,
        method: "POST",
        body: queryArg.createClassroomCommentInput,
      }),
    }),
    deleteClassroomComment: build.mutation<DeleteClassroomCommentApiResponse, DeleteClassroomCommentApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/comments/${queryArg.commentId}`,
        method: "DELETE",
      }),
    }),
    reportClassroomComment: build.mutation<ReportClassroomCommentApiResponse, ReportClassroomCommentApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/comments/${queryArg.commentId}/report`,
        method: "POST",
        body: queryArg.reportClassroomCommentInput,
      }),
    }),
    getClassroomCameraToken: build.query<GetClassroomCameraTokenApiResponse, GetClassroomCameraTokenApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/generate_camera_token`,
      }),
    }),
    getClassroomToken: build.query<GetClassroomTokenApiResponse, GetClassroomTokenApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/generate_token`,
      }),
    }),
    getClassroomVideoToken: build.query<GetClassroomVideoTokenApiResponse, GetClassroomVideoTokenApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/generate_video_token`,
      }),
    }),
    prepareClassroom: build.query<PrepareClassroomApiResponse, PrepareClassroomApiArg>({
      query: (queryArg) => ({ url: `/v1/classrooms/${queryArg.id}/prepare` }),
    }),
    reserveClassroom: build.mutation<ReserveClassroomApiResponse, ReserveClassroomApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/reservations`,
        method: "POST",
      }),
    }),
    cancelClassroom: build.mutation<CancelClassroomApiResponse, CancelClassroomApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/reservations`,
        method: "DELETE",
      }),
    }),
    joinClassroom: build.mutation<JoinClassroomApiResponse, JoinClassroomApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/join`,
        method: "POST",
        body: queryArg.joinClassroomInput,
      }),
    }),
    leaveClassroom: build.mutation<LeaveClassroomApiResponse, LeaveClassroomApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/leave`,
        method: "DELETE",
      }),
    }),
    getClassroomLink: build.query<GetClassroomLinkApiResponse, GetClassroomLinkApiArg>({
      query: (queryArg) => ({ url: `/v1/classrooms/${queryArg.id}/link` }),
    }),
    muteClassroomVoice: build.mutation<MuteClassroomVoiceApiResponse, MuteClassroomVoiceApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/muting`,
        method: "PATCH",
        body: queryArg.muteClassroomVoiceInput,
      }),
    }),
    reactClassroom: build.mutation<ReactClassroomApiResponse, ReactClassroomApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/reactions`,
        method: "POST",
        body: queryArg.reactClassroomInput,
      }),
    }),
    getClassroomShare: build.query<GetClassroomShareApiResponse, GetClassroomShareApiArg>({
      query: (queryArg) => ({ url: `/v1/classrooms/${queryArg.id}/share` }),
    }),
    kickClassroomUser: build.mutation<KickClassroomUserApiResponse, KickClassroomUserApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/users/${queryArg.userId}/kick`,
        method: "POST",
      }),
    }),
    updateClassroomUserRole: build.mutation<UpdateClassroomUserRoleApiResponse, UpdateClassroomUserRoleApiArg>({
      query: (queryArg) => ({
        url: `/v1/classrooms/${queryArg.id}/users/${queryArg.userId}/roles`,
        method: "PUT",
        body: queryArg.updateClassroomUserRoleInput,
      }),
    }),
    listEvents: build.query<ListEventsApiResponse, ListEventsApiArg>({
      query: () => ({ url: `/v1/events` }),
    }),
    getEvent: build.query<GetEventApiResponse, GetEventApiArg>({
      query: (queryArg) => ({ url: `/v1/events/${queryArg.id}` }),
    }),
    createEventPaymentIntent: build.mutation<CreateEventPaymentIntentApiResponse, CreateEventPaymentIntentApiArg>({
      query: (queryArg) => ({
        url: `/v1/events/${queryArg.id}/payment_intent`,
        method: "POST",
        body: queryArg.createEventPaymentIntentInput,
      }),
    }),
    getMe: build.query<GetMeApiResponse, GetMeApiArg>({
      query: () => ({ url: `/v1/me` }),
    }),
    updateMe: build.mutation<UpdateMeApiResponse, UpdateMeApiArg>({
      query: (queryArg) => ({
        url: `/v1/me`,
        method: "PATCH",
        body: queryArg.updateMeInput,
      }),
    }),
    deleteMe: build.mutation<DeleteMeApiResponse, DeleteMeApiArg>({
      query: (queryArg) => ({
        url: `/v1/me`,
        method: "DELETE",
        body: queryArg.deleteMeInput,
      }),
    }),
    createMeSubscription: build.mutation<CreateMeSubscriptionApiResponse, CreateMeSubscriptionApiArg>({
      query: () => ({ url: `/v1/me/app_subscription`, method: "POST" }),
    }),
    listAudEvents: build.query<ListAudEventsApiResponse, ListAudEventsApiArg>({
      query: () => ({ url: `/v1/me/aud/events` }),
    }),
    getAudEvent: build.query<GetAudEventApiResponse, GetAudEventApiArg>({
      query: (queryArg) => ({ url: `/v1/me/aud/events/${queryArg.id}` }),
    }),
    participateEventPremium: build.mutation<ParticipateEventPremiumApiResponse, ParticipateEventPremiumApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/event/${queryArg.id}/premium_participation`,
        method: "POST",
      }),
    }),
    participateEventStudent: build.mutation<ParticipateEventStudentApiResponse, ParticipateEventStudentApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/event/${queryArg.id}/student_participation`,
        method: "POST",
      }),
    }),
    acceptEventInvitation: build.mutation<AcceptEventInvitationApiResponse, AcceptEventInvitationApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/event/${queryArg.id}/invitation`,
        method: "POST",
        body: queryArg.acceptEventInvitationInput,
      }),
    }),
    participateEvent: build.mutation<ParticipateEventApiResponse, ParticipateEventApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/events/${queryArg.id}/participation`,
        method: "POST",
      }),
    }),
    createEventUserPaymentIntent: build.mutation<
      CreateEventUserPaymentIntentApiResponse,
      CreateEventUserPaymentIntentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/me/events/${queryArg.id}/payment_intent`,
        method: "POST",
        body: queryArg.createEventUserPaymentIntentInput,
      }),
    }),
    listMeFollowing: build.query<ListMeFollowingApiResponse, ListMeFollowingApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/following`,
        params: { limit: queryArg.limit },
      }),
    }),
    listMeNotificationSettings: build.query<ListMeNotificationSettingsApiResponse, ListMeNotificationSettingsApiArg>({
      query: () => ({ url: `/v1/me/notification_settings` }),
    }),
    updateNotificationSetting: build.mutation<UpdateNotificationSettingApiResponse, UpdateNotificationSettingApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/notification_settings`,
        method: "POST",
        body: queryArg.updateNotificationSettingInput,
      }),
    }),
    listMeNotifications: build.query<ListMeNotificationsApiResponse, ListMeNotificationsApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/notifications`,
        params: { cursor: queryArg.cursor },
      }),
    }),
    readNotification: build.mutation<ReadNotificationApiResponse, ReadNotificationApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/notifications/${queryArg.id}/read`,
        method: "PUT",
        body: queryArg.readNotificationInput,
      }),
    }),
    listMeSaleHistories: build.query<ListMeSaleHistoriesApiResponse, ListMeSaleHistoriesApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/sale-histories`,
        params: { limit: queryArg.limit, cursor: queryArg.cursor },
      }),
    }),
    signOut: build.mutation<SignOutApiResponse, SignOutApiArg>({
      query: () => ({ url: `/v1/me/sign_out`, method: "DELETE" }),
    }),
    getMeStripeCard: build.query<GetMeStripeCardApiResponse, GetMeStripeCardApiArg>({
      query: () => ({ url: `/v1/me/stripe-card` }),
    }),
    getStripeConnect: build.query<GetStripeConnectApiResponse, GetStripeConnectApiArg>({
      query: () => ({ url: `/v1/me/stripe-connect` }),
    }),
    createStripeConnect: build.mutation<CreateStripeConnectApiResponse, CreateStripeConnectApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/stripe-connect`,
        method: "POST",
        body: queryArg.createStripeConnectInput,
      }),
    }),
    transferToStripeAccount: build.mutation<TransferToStripeAccountApiResponse, TransferToStripeAccountApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/stripe-connect/transfer`,
        method: "POST",
        body: queryArg.transferToStripeAccountInput,
      }),
    }),
    createSubscription: build.mutation<CreateSubscriptionApiResponse, CreateSubscriptionApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/subscriptions`,
        method: "POST",
        body: queryArg.createSubscriptionInput,
      }),
    }),
    updateSubscription: build.mutation<UpdateSubscriptionApiResponse, UpdateSubscriptionApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/subscriptions`,
        method: "PATCH",
        body: queryArg.updateSubscriptionInput,
      }),
    }),
    deleteSubscription: build.mutation<DeleteSubscriptionApiResponse, DeleteSubscriptionApiArg>({
      query: (queryArg) => ({
        url: `/v1/me/subscriptions`,
        method: "DELETE",
        body: queryArg.deleteSubscriptionInput,
      }),
    }),
    getMeSubscriptionTrial: build.query<GetMeSubscriptionTrialApiResponse, GetMeSubscriptionTrialApiArg>({
      query: () => ({ url: `/v1/me/subscription_trial` }),
    }),
    getNotification: build.query<GetNotificationApiResponse, GetNotificationApiArg>({
      query: (queryArg) => ({ url: `/v1/notifications/${queryArg.id}` }),
    }),
    signUpWithFirebaseProfessional: build.mutation<
      SignUpWithFirebaseProfessionalApiResponse,
      SignUpWithFirebaseProfessionalApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/professional/signup`,
        method: "POST",
        body: queryArg.signUpWithFirebaseProfessionalInput,
      }),
    }),
    listProfessionals: build.query<ListProfessionalsApiResponse, ListProfessionalsApiArg>({
      query: (queryArg) => ({
        url: `/v1/professionals`,
        params: {
          sort: queryArg.sort,
          limit: queryArg.limit,
          cursor: queryArg.cursor,
        },
      }),
    }),
    listSubscriptionPlans: build.query<ListSubscriptionPlansApiResponse, ListSubscriptionPlansApiArg>({
      query: () => ({ url: `/v1/subscription_plans` }),
    }),
    signInWithFirebase: build.mutation<SignInWithFirebaseApiResponse, SignInWithFirebaseApiArg>({
      query: (queryArg) => ({
        url: `/v1/user/signin`,
        method: "POST",
        body: queryArg.signInWithFirebaseInput,
      }),
    }),
    signInAnonymous: build.mutation<SignInAnonymousApiResponse, SignInAnonymousApiArg>({
      query: () => ({ url: `/v1/user/signin/anonymous`, method: "POST" }),
    }),
    signUpWithFirebase: build.mutation<SignUpWithFirebaseApiResponse, SignUpWithFirebaseApiArg>({
      query: (queryArg) => ({
        url: `/v1/user/signup`,
        method: "POST",
        body: queryArg.signUpWithFirebaseInput,
      }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({ url: `/v1/users/${queryArg.id}` }),
    }),
    listUserArchives: build.query<ListUserArchivesApiResponse, ListUserArchivesApiArg>({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.id}/archives`,
        params: { cursor: queryArg.cursor, limit: queryArg.limit },
      }),
    }),
    followUser: build.mutation<FollowUserApiResponse, FollowUserApiArg>({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.id}/follow`,
        method: "POST",
      }),
    }),
    listUserFollowers: build.query<ListUserFollowersApiResponse, ListUserFollowersApiArg>({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.id}/followers`,
        params: { cursor: queryArg.cursor, limit: queryArg.limit },
      }),
    }),
    listUserFollowing: build.query<ListUserFollowingApiResponse, ListUserFollowingApiArg>({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.id}/following`,
        params: { cursor: queryArg.cursor, limit: queryArg.limit },
      }),
    }),
    unfollowUser: build.mutation<UnfollowUserApiResponse, UnfollowUserApiArg>({
      query: (queryArg) => ({
        url: `/v1/users/${queryArg.id}/unfollow`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as nowdoApiBase };
export type GetAppSubscriptionPlanApiResponse = /** status 200  */ GetAppSubscriptionPlanPayload;
export type GetAppSubscriptionPlanApiArg = void;
export type ListEventArchivesApiResponse = /** status 200  */ Event[];
export type ListEventArchivesApiArg = {
  limit?: number;
};
export type ListArchivesApiResponse = /** status 200  */ ListArchivesPayload;
export type ListArchivesApiArg = {
  pickup?: boolean;
  cursor?: string;
  limit?: number;
};
export type GetArchiveApiResponse = /** status 200  */ GetArchivePayload;
export type GetArchiveApiArg = {
  id: string;
};
export type ListRecommendArchivesApiResponse = /** status 200  */ ListRecommendArchivesPayload;
export type ListRecommendArchivesApiArg = {
  /** archive id */
  id: string;
  /** limit */
  limit?: number;
};
export type WatchArchiveApiResponse = /** status 200  */ WatchArchivePayload;
export type WatchArchiveApiArg = {
  /** archive id */
  id: string;
};
export type ResetPasswordApiResponse = unknown;
export type ResetPasswordApiArg = {
  resetPasswordInput: ResetPasswordInput;
};
export type SendResetPasswordLinkApiResponse = unknown;
export type SendResetPasswordLinkApiArg = {
  sendResetPasswordLinkInput: SendResetPasswordLinkInput;
};
export type SignInApiResponse = /** status 200  */ SignInPayload;
export type SignInApiArg = {
  signInInput: SignInInput;
};
export type SignInWithPartnerSchoolApiResponse = /** status 200  */ SignInWithPartnerSchoolPayload;
export type SignInWithPartnerSchoolApiArg = {
  signInWithPartnerSchoolInput: SignInWithPartnerSchoolInput;
};
export type SignUpApiResponse = /** status 200  */ SignUpPayload;
export type SignUpApiArg = {
  signUpInput: SignUpInput;
};
export type SignUpWithProfessionalApiResponse = /** status 200  */ SignUpPayload;
export type SignUpWithProfessionalApiArg = {
  signUpWithProfessionalInput: SignUpWithProfessionalInput;
};
export type SignUpWithPartnerSchoolApiResponse = /** status 201  */ SignUpWithPartnerSchoolPayload;
export type SignUpWithPartnerSchoolApiArg = {
  signUpWithPartnerSchoolInput: SignUpWithPartnerSchoolInput;
};
export type RefreshAccessTokenApiResponse = /** status 200  */ RefreshAccessTokenPayload;
export type RefreshAccessTokenApiArg = {
  refreshAccessTokenInput: RefreshAccessTokenInput;
};
export type VerifyEmailApiResponse = unknown;
export type VerifyEmailApiArg = {
  body: {
    token: string;
  };
};
export type ListCategoriesApiResponse = /** status 200  */ ListCategoriesPayload;
export type ListCategoriesApiArg = void;
export type ListCategoryArchivesApiResponse = /** status 200  */ ListArchivesPayload;
export type ListCategoryArchivesApiArg = {
  /** category id */
  id: string;
  /** next cursor */
  cursor?: string;
  /** limit */
  limit?: number;
};
export type VerifyTrialCouponApiResponse = /** status 200  */ VerifyTrialCouponPayload;
export type VerifyTrialCouponApiArg = {
  verifyTrialCouponInput: VerifyTrialCouponInput;
};
export type ListClassroomsApiResponse = /** status 200  */ ListClassroomsPayload;
export type ListClassroomsApiArg = {
  state?: State;
  userId?: string;
};
export type CreateClassroomApiResponse = /** status 200  */ Classroom;
export type CreateClassroomApiArg = {
  createClassroomInput: CreateClassroomInput;
};
export type GetClassroomApiResponse = /** status 200  */ GetClassroomPayload;
export type GetClassroomApiArg = {
  id: string;
};
export type UpdateClassroomApiResponse = unknown;
export type UpdateClassroomApiArg = {
  id: string;
  updateClassroomInput: UpdateClassroomInput;
};
export type DeleteClassroomApiResponse = unknown;
export type DeleteClassroomApiArg = {
  id: string;
};
export type ListClassroomCommentsApiResponse = /** status 200  */ ListClassroomCommentsPayload;
export type ListClassroomCommentsApiArg = {
  id: string;
  cursor?: string;
  order?: "asc" | "desc";
  limit?: number;
};
export type CreateClassroomCommentApiResponse = unknown;
export type CreateClassroomCommentApiArg = {
  id: string;
  createClassroomCommentInput: CreateClassroomCommentInput;
};
export type DeleteClassroomCommentApiResponse = unknown;
export type DeleteClassroomCommentApiArg = {
  id: string;
  commentId: string;
};
export type ReportClassroomCommentApiResponse = unknown;
export type ReportClassroomCommentApiArg = {
  id: string;
  commentId: string;
  reportClassroomCommentInput: ReportClassroomCommentInput;
};
export type GetClassroomCameraTokenApiResponse = /** status 200  */ GetClassroomTokenPayload;
export type GetClassroomCameraTokenApiArg = {
  id: string;
};
export type GetClassroomTokenApiResponse = /** status 200  */ GetClassroomTokenPayload;
export type GetClassroomTokenApiArg = {
  id: string;
};
export type GetClassroomVideoTokenApiResponse = /** status 200  */ GetClassroomTokenPayload;
export type GetClassroomVideoTokenApiArg = {
  id: string;
};
export type PrepareClassroomApiResponse = /** status 200  */ {
  success: boolean;
};
export type PrepareClassroomApiArg = {
  id: string;
};
export type ReserveClassroomApiResponse = unknown;
export type ReserveClassroomApiArg = {
  id: string;
};
export type CancelClassroomApiResponse = unknown;
export type CancelClassroomApiArg = {
  id: string;
};
export type JoinClassroomApiResponse = unknown;
export type JoinClassroomApiArg = {
  id: string;
  joinClassroomInput: JoinClassroomInput;
};
export type LeaveClassroomApiResponse = unknown;
export type LeaveClassroomApiArg = {
  id: string;
};
export type GetClassroomLinkApiResponse = /** status 200  */ GetClassroomLinkPayload;
export type GetClassroomLinkApiArg = {
  id: string;
};
export type MuteClassroomVoiceApiResponse = unknown;
export type MuteClassroomVoiceApiArg = {
  id: string;
  muteClassroomVoiceInput: MuteClassroomVoiceInput;
};
export type ReactClassroomApiResponse = unknown;
export type ReactClassroomApiArg = {
  id: string;
  reactClassroomInput: ReactClassroomInput;
};
export type GetClassroomShareApiResponse = /** status 200  */ GetClassroomSharePayload;
export type GetClassroomShareApiArg = {
  id: string;
};
export type KickClassroomUserApiResponse = unknown;
export type KickClassroomUserApiArg = {
  id: string;
  userId: string;
};
export type UpdateClassroomUserRoleApiResponse = unknown;
export type UpdateClassroomUserRoleApiArg = {
  id: string;
  userId: string;
  updateClassroomUserRoleInput: UpdateClassroomUserRoleInput;
};
export type ListEventsApiResponse = /** status 200  */ ListEventsPayload;
export type ListEventsApiArg = void;
export type GetEventApiResponse = /** status 200  */ Event;
export type GetEventApiArg = {
  id?: string;
};
export type CreateEventPaymentIntentApiResponse = /** status 200  */ CreateEventPaymentIntentPayload;
export type CreateEventPaymentIntentApiArg = {
  id?: string;
  createEventPaymentIntentInput: CreateEventPaymentIntentInput;
};
export type GetMeApiResponse = /** status 200  */ Me;
export type GetMeApiArg = void;
export type UpdateMeApiResponse = /** status 200  */ Me;
export type UpdateMeApiArg = {
  updateMeInput: UpdateMeInput;
};
export type DeleteMeApiResponse = unknown;
export type DeleteMeApiArg = {
  deleteMeInput: DeleteMeInput;
};
export type CreateMeSubscriptionApiResponse = /** status 200  */ Me;
export type CreateMeSubscriptionApiArg = void;
export type ListAudEventsApiResponse = /** status 200  */ ListAudEventsPayload;
export type ListAudEventsApiArg = void;
export type GetAudEventApiResponse = /** status 200  */ Event;
export type GetAudEventApiArg = {
  id?: string;
};
export type ParticipateEventPremiumApiResponse = /** status 200  */ ParticipateEventPayload;
export type ParticipateEventPremiumApiArg = {
  id?: string;
};
export type ParticipateEventStudentApiResponse = /** status 200  */ ParticipateEventPayload;
export type ParticipateEventStudentApiArg = {
  id?: string;
};
export type AcceptEventInvitationApiResponse = /** status 200  */ Event;
export type AcceptEventInvitationApiArg = {
  id?: string;
  acceptEventInvitationInput: AcceptEventInvitationInput;
};
export type ParticipateEventApiResponse = /** status 200  */ ParticipateEventPayload;
export type ParticipateEventApiArg = {
  id?: string;
};
export type CreateEventUserPaymentIntentApiResponse = /** status 200  */ CreateEventUserPaymentIntentPayload;
export type CreateEventUserPaymentIntentApiArg = {
  id?: string;
  createEventUserPaymentIntentInput: CreateEventUserPaymentIntentInput;
};
export type ListMeFollowingApiResponse = /** status 200  */ ListMeFollowingPayload;
export type ListMeFollowingApiArg = {
  limit?: number;
};
export type ListMeNotificationSettingsApiResponse = /** status 200  */ ListMeNotificationSettingsPayload;
export type ListMeNotificationSettingsApiArg = void;
export type UpdateNotificationSettingApiResponse = unknown;
export type UpdateNotificationSettingApiArg = {
  updateNotificationSettingInput: UpdateNotificationSettingInput;
};
export type ListMeNotificationsApiResponse = /** status 200  */ ListMeNotificationsPayload;
export type ListMeNotificationsApiArg = {
  cursor?: string;
};
export type ReadNotificationApiResponse = unknown;
export type ReadNotificationApiArg = {
  id: string;
  readNotificationInput: ReadNotificationInput;
};
export type ListMeSaleHistoriesApiResponse = /** status 200  */ ListMeSaleHistories;
export type ListMeSaleHistoriesApiArg = {
  limit?: number;
  cursor?: string;
};
export type SignOutApiResponse = unknown;
export type SignOutApiArg = void;
export type GetMeStripeCardApiResponse = /** status 200  */ StripeCard;
export type GetMeStripeCardApiArg = void;
export type GetStripeConnectApiResponse = /** status 200  */ StripeConnect;
export type GetStripeConnectApiArg = void;
export type CreateStripeConnectApiResponse = /** status 200  */ CreateStripeConnectPayload;
export type CreateStripeConnectApiArg = {
  createStripeConnectInput: CreateStripeConnectInput;
};
export type TransferToStripeAccountApiResponse = /** status 200  */ TransferToStripeAccountPayload;
export type TransferToStripeAccountApiArg = {
  transferToStripeAccountInput: TransferToStripeAccountInput;
};
export type CreateSubscriptionApiResponse = /** status 201  */ Subscription;
export type CreateSubscriptionApiArg = {
  createSubscriptionInput: CreateSubscriptionInput;
};
export type UpdateSubscriptionApiResponse = /** status 200  */ Subscription;
export type UpdateSubscriptionApiArg = {
  updateSubscriptionInput: UpdateSubscriptionInput;
};
export type DeleteSubscriptionApiResponse = /** status 200  */ Subscription;
export type DeleteSubscriptionApiArg = {
  deleteSubscriptionInput: DeleteSubscriptionInput;
};
export type GetMeSubscriptionTrialApiResponse = /** status 200  */ {
  trial: boolean;
};
export type GetMeSubscriptionTrialApiArg = void;
export type GetNotificationApiResponse = /** status 200  */ GetNotificationPayload;
export type GetNotificationApiArg = {
  id: string;
};
export type SignUpWithFirebaseProfessionalApiResponse = /** status 200  */ SignUpPayload;
export type SignUpWithFirebaseProfessionalApiArg = {
  signUpWithFirebaseProfessionalInput: SignUpWithFirebaseProfessionalInput;
};
export type ListProfessionalsApiResponse = /** status 200  */ ListProfessionalsPayload;
export type ListProfessionalsApiArg = {
  sort?: ProfessionalSort;
  limit?: number;
  cursor?: string;
};
export type ListSubscriptionPlansApiResponse = /** status 200  */ ListSubscriptionPlansPayload;
export type ListSubscriptionPlansApiArg = void;
export type SignInWithFirebaseApiResponse = /** status 200  */ SignInPayload;
export type SignInWithFirebaseApiArg = {
  signInWithFirebaseInput: SignInWithFirebaseInput;
};
export type SignInAnonymousApiResponse = /** status 200  */ SignInAnonymousPayload;
export type SignInAnonymousApiArg = void;
export type SignUpWithFirebaseApiResponse = /** status 200  */ SignUpPayload;
export type SignUpWithFirebaseApiArg = {
  signUpWithFirebaseInput: SignUpWithFirebaseInput;
};
export type GetUserApiResponse = /** status 200  */ User;
export type GetUserApiArg = {
  id: string;
};
export type ListUserArchivesApiResponse = /** status 200  */ ListUserArchivesPayload;
export type ListUserArchivesApiArg = {
  /** user id */
  id: string;
  /** next cursor */
  cursor?: string;
  /** limit */
  limit?: number;
};
export type FollowUserApiResponse = unknown;
export type FollowUserApiArg = {
  id: string;
};
export type ListUserFollowersApiResponse = /** status 200  */ ListUserFollowersPayload;
export type ListUserFollowersApiArg = {
  id: string;
  cursor?: string;
  limit?: number;
};
export type ListUserFollowingApiResponse = /** status 200  */ ListUserFollowingPayload;
export type ListUserFollowingApiArg = {
  id: string;
  cursor?: string;
  limit?: number;
};
export type UnfollowUserApiResponse = unknown;
export type UnfollowUserApiArg = {
  id: string;
};
export type AppSubscriptionPlan = {
  id: string;
  trial_days: number;
  has_trial: boolean;
};
export type GetAppSubscriptionPlanPayload = {
  plan: AppSubscriptionPlan;
};
export type Interest = {
  id: string;
  name: string;
  children: Interest[];
};
export type User = {
  id: string;
  name: string;
  avatarUrl: string;
  introduction: string;
  isProfessional: boolean;
  professionalTitle: string;
  isPremium: boolean;
  isStudentAge: boolean;
  followerCount: number;
  followingCount: number;
  isFollowed: boolean;
  interests: Interest[];
  archiveCount?: number;
  scheduledClassroomCount?: number;
  registeredAt: number;
  avatar_url?: string;
  is_professional?: boolean;
  professional_title?: string;
  is_premium?: boolean;
  is_student_age?: boolean;
  follower_count?: number;
  following_count?: number;
  is_followed?: boolean;
  registered_at?: number;
};
export type CoStar = {
  id: string;
  name: string;
};
export type Language = "ja" | "en_US";
export type Category = {
  id: string;
  name: string;
  imageUrl: string;
  order: number;
};
export type Event = {
  id: string;
  title: string;
  subtitle: string;
  detail: string;
  started_at: number;
  ended_at: number;
  fee: number;
  organizer?: User;
  co_stars: CoStar[];
  cover_image_url: string;
  state: string;
  public: boolean;
  archive_file_id: string;
  category_id: string;
  limited_number: string;
  joined: boolean;
  joined_zoom_url: string;
  language: Language;
  category: Category;
  tags: string[];
};
export type Archive = {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  owners: User[];
  pickup: boolean;
  watchCount: number;
  category: Category;
  watchedAt?: number | null;
};
export type ListArchivesPayload = {
  archives: Archive[];
  count: number;
  cursor: string;
};
export type GetArchivePayload = {
  archive: Archive;
};
export type ListRecommendArchivesPayload = {
  archives: Archive[];
};
export type WatchArchivePayload = {
  cookie: string;
  url: string;
};
export type ResetPasswordInput = {
  token: string;
  password: string;
};
export type SendResetPasswordLinkInput = {
  email: string;
};
export type SignInPayload = {
  token: string;
  refreshToken: string;
};
export type SignInInput = {
  email: string;
  password: string;
};
export type SignInWithPartnerSchoolPayload = {
  customToken: string;
  token: string;
  refreshToken: string;
};
export type SignInWithPartnerSchoolInput = {
  token: string;
};
export type SignUpPayload = {
  token: string;
  refreshToken: string;
};
export type SignUpInput = {
  email: string;
  password: string;
  nickname: string;
  birthDate: number;
  language?: string;
};
export type SignUpWithProfessionalInput = {
  email: string;
  password: string;
  nickname: string;
  birthDate: number;
  language?: string;
  job: string;
  introduction: string;
  avatarUrl: string;
  code?: string;
};
export type SignUpWithPartnerSchoolPayload = {
  customToken: string;
  token: string;
  refreshToken: string;
};
export type SignUpWithPartnerSchoolInput = {
  token: string;
  nickname: string;
  birthDate: number;
};
export type RefreshAccessTokenPayload = {
  token: string;
  refreshToken: string;
};
export type RefreshAccessTokenInput = {
  refreshToken: string;
};
export type ListCategoriesPayload = {
  categories: Category[];
};
export type TrialCoupon = {
  id: string;
  expiresAt: number;
  limitCount: number;
  usedCount: number;
  trialPeriodDays: number;
};
export type VerifyTrialCouponPayload = {
  trialCoupon: TrialCoupon;
  valid: boolean;
};
export type VerifyTrialCouponInput = {
  code: string;
};
export type ClassroomUser = {
  id: string;
  fullName: string;
  avatarUrl: string;
  isProfessional: boolean;
  role: "speaker" | "audience" | "anonymous";
};
export type Classroom = {
  id: string;
  name: string;
  description: string;
  userCount: number;
  reservationUserCount: number;
  imageURL: string;
  category: Category;
  interests: Interest[];
  privateMode: boolean;
  audienceMode: boolean;
  baned: boolean;
  user: User;
  users: ClassroomUser[];
  displayedUsers: ClassroomUser[];
  reservedUsers: ClassroomUser[];
  state: "scheduled" | "opened" | "closed";
  reserved: boolean;
  startAt: number;
  endAt: number;
  pickup: boolean;
  allUsers: boolean;
};
export type ListClassroomsPayload = {
  classrooms: Classroom[];
};
export type State = "scheduled" | "opened" | "closed";
export type CreateClassroomInput = {
  name: string;
  description?: string;
  startAt: number;
  minutes?: number;
  categoryId?: string;
  interestIds?: string[];
  canAdultParticipate?: boolean;
  allUsers?: boolean;
  audienceMode?: boolean;
  privateMode?: boolean;
};
export type GetClassroomPayload = {
  classroom: Classroom;
};
export type UpdateClassroomInput = {
  name?: string;
  description?: string;
  categoryId?: string;
  startAt?: number;
  interestIds?: string[];
  canAdultParticipate?: boolean;
  allUsers?: boolean;
  audienceMode?: boolean;
  privateMode?: boolean;
};
export type ClassroomCommentUser = {
  id: string;
  name: string;
  avatarUrl: string;
};
export type ClassroomComment = {
  id: string;
  user: ClassroomCommentUser;
  text: string;
  createdAt: number;
};
export type ListClassroomCommentsPayload = {
  comments: ClassroomComment[];
  cursor: string;
};
export type CreateClassroomCommentInput = {
  text: string;
};
export type ReportClassroomCommentInput = {
  reason: string;
};
export type GetClassroomTokenPayload = {
  token: string;
};
export type PrepareClassroomErrorCode = "ALREADY_PARTICIPATING" | "CANT_PARTICIPATE";
export type JoinClassroomInput = {
  agora_user_id?: number;
};
export type GetClassroomLinkPayload = {
  url: string;
};
export type MuteClassroomVoiceInput = {
  is_muted: boolean;
};
export type ReactClassroomInput = {
  reactionName: "heart" | "+1" | "hand" | "clap" | "double";
};
export type GetClassroomSharePayload = {
  text: string;
  url: string;
};
export type Role = "speaker" | "audience" | "anonymous";
export type UpdateClassroomUserRoleInput = {
  role: Role;
};
export type ListEventsPayload = Event[];
export type CreateEventPaymentIntentPayload = {
  client_secret: string;
};
export type CreateEventPaymentIntentInput = {
  email: string;
};
export type Platform = "iOS" | "Android" | "Web";
export type Me = {
  id: string;
  nickname: string;
  email: string;
  emailVerified: boolean;
  avatarUrl: string;
  job?: string;
  introduction: string;
  language: Language;
  birthDate: number;
  followerCount?: number;
  isStudentAge: boolean;
  canCommunicateAge: boolean;
  state: string;
  isSubscribed: boolean;
  subscribingPlatform: Platform;
  unreadNotificationCount: number;
  willUnsubscribed: boolean;
  subscriptionExpiresAt: number;
  stripeSubscriptionExpiresAt: number;
  stripeInvoiceStatus: string;
  isPartnerSchoolStudent: boolean;
  isProfessional: boolean;
  interests: Interest[];
};
export type UpdateMeInput = {
  email?: string;
  nickname?: string;
  password?: string;
  job?: string;
  introduction?: string;
  birth_date?: number;
  birthDate?: number;
  avatar_url?: string;
  avatarUrl?: string;
  id_card_image?: string;
  idCardImage?: string;
  interestIds?: string[];
};
export type DeleteMeInput = {
  withdrawalReason?: string;
  withdrawal_reason?: string;
};
export type ListAudEventsPayload = Event[];
export type ParticipateEventPayload = {
  participated: boolean;
};
export type AcceptEventInvitationInput = {
  code: string;
};
export type CreateEventUserPaymentIntentPayload = {
  client_secret: string;
};
export type CreateEventUserPaymentIntentInput = {
  save_card: boolean;
};
export type UserFollow = {
  id: string;
  name: string;
  avatar_url?: string;
  avatarUrl: string;
  introduction: string;
  is_followed?: boolean;
  isFollowed: boolean;
  follower_count_you_follow?: number;
  followerCountYouFollow: number;
  classroom_id?: string;
  classroomId: string;
};
export type ListMeFollowingPayload = {
  following: UserFollow[];
};
export type UserNotificationSetting = {
  id: string;
  enable: boolean;
};
export type ListMeNotificationSettingsPayload = {
  data: UserNotificationSetting[];
};
export type UpdateNotificationSettingInput = {
  data: UserNotificationSetting[];
};
export type UserNotificationItemEvent = {
  id: string;
  title: string;
  cover_image_url: string;
  started_at: number;
  ended_at: number;
};
export type UserNotificationItemNotification = {
  id: string;
  title: string;
  content: string;
};
export type UserNotificationItemUser = {
  id: string;
  name: string;
  avatar_url: string;
};
export type UserNotification = {
  id: string;
  type: number;
  title: string;
  notified_at: number;
  read: boolean;
  event_id?: string;
  notification_id?: string;
  user_id?: string;
  event?: UserNotificationItemEvent;
  classroom?: {
    id: string;
    name: string;
  };
  notification?: UserNotificationItemNotification;
  user?: UserNotificationItemUser;
};
export type ListMeNotificationsPayload = {
  data: UserNotification[];
  next_cursor: string;
};
export type ReadNotificationInput = {
  read: boolean;
};
export type ProfessionalSaleHistory = {
  id: string;
  timestamp: number;
  note: string;
  amount: number;
  beforeAmount: number;
};
export type ListMeSaleHistories = {
  histories: ProfessionalSaleHistory[];
  cursor: string;
};
export type StripeCard = {
  last4: string;
  expYear: number;
  expMonth: number;
};
export type StripeConnect = {
  state: string;
  dashboardUrl: string;
};
export type CreateStripeConnectPayload = {
  id: string;
  stripeId: string;
};
export type CreateStripeConnectInput = {
  code: string;
  state: string;
};
export type TransferToStripeAccountPayload = {
  history: ProfessionalSaleHistory;
};
export type TransferToStripeAccountInput = {
  latestHistoryId: string;
};
export type SubscriptionStatus =
  | "active"
  | "all"
  | "canceled"
  | "incomplete"
  | "incomplete_expired"
  | "past_due"
  | "trialing"
  | "unpaid";
export type Subscription = {
  id: string;
  current_period_start: number;
  current_period_end: number;
  status: SubscriptionStatus;
};
export type CreateSubscriptionInput = {
  paymentMethodId: string;
  priceId: string;
  trialCouponId?: string;
  payment_method_id?: string;
  price_id?: string;
  trial_coupon_id?: string;
};
export type UpdateSubscriptionInput = {
  paymentMethodId: string;
  payment_method_id?: string;
};
export type DeleteSubscriptionInput = {
  reason?: string;
  withdrawal_reason?: string;
};
export type Notification = {
  id: string;
  title: string;
  content: string;
  publish_at: number;
};
export type GetNotificationPayload = {
  data: Notification;
};
export type SignUpWithFirebaseProfessionalInput = {
  idToken: string;
  id_token?: string;
  email: string;
  family_name?: string;
  given_name?: string;
  nickname: string;
  introduction: string;
  job: string;
  avatarUrl: string;
  avatar_url?: string;
  birthDate: number;
  birth_date?: number;
  language: string;
  code?: string;
};
export type ListProfessionalsPayload = {
  professionals: User[];
  cursor: string;
};
export type ProfessionalSort = "archive" | "classroom";
export type SubscriptionPlan = {
  id: string;
  type: string;
  name: string;
  amount: number;
  country: string;
  currency: string;
};
export type ListSubscriptionPlansPayload = {
  plans: SubscriptionPlan[];
};
export type SignInWithFirebaseInput = {
  id_token: string;
};
export type SignInAnonymousPayload = {
  token: string;
  id: string;
};
export type SignUpWithFirebaseInput = {
  idToken: string;
  id_token?: string;
  family_name?: string;
  given_name?: string;
  email: string;
  nickname: string;
  birthDate: number;
  birth_date?: number;
  language: string;
  idCardImage?: string;
  id_card_image?: string;
};
export type ListUserArchivesPayload = {
  archives: Archive[];
  cursor: string;
};
export type ListUserFollowersPayload = {
  followers: UserFollow[];
  cursor: string;
};
export type ListUserFollowingPayload = {
  following: UserFollow[];
  cursor: string;
};
