import { FirebaseApp, getApps, initializeApp } from "firebase/app";
import { connectFirestoreEmulator, Firestore, getFirestore } from "firebase/firestore";
import { assertsString } from "~/submodules/assertsString";

interface FirebaseCredential {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
}

const getClientCredentials = (): FirebaseCredential => {
  const apiKey = process.env.NEXT_PUBLIC_FIREBASE_API_KEY;
  const authDomain = process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN;
  const databaseURL = process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL;
  const projectId = process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID;
  const storageBucket = process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET;
  const messagingSenderId = process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID;
  const appId = process.env.NEXT_PUBLIC_FIREBASE_APP_ID;
  assertsString(apiKey);
  assertsString(authDomain);
  assertsString(databaseURL);
  assertsString(projectId);
  assertsString(storageBucket);
  assertsString(messagingSenderId);
  assertsString(appId);

  return {
    apiKey,
    authDomain,
    databaseURL,
    projectId,
    storageBucket,
    messagingSenderId,
    appId,
  };
};

const clientCredentials = getClientCredentials();

export let app: FirebaseApp;
export let firestore: Firestore;

// Check that `window` is in scope for the analytics module!
if (typeof window !== "undefined" && !getApps().length) {
  app = initializeApp(clientCredentials);

  firestore = getFirestore(app);
  if (process.env.FIRESTORE_EMULATOR_HOST) {
    const [host, port] = process.env.FIRESTORE_EMULATOR_HOST.split(":");
    if (host && port) {
      connectFirestoreEmulator(firestore, host, +port);
    }
  }
}
