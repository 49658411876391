import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { nowdoApi } from "hooks/rtk-query-api";
import { NextPageContext } from "next";
import { createWrapper, Context } from "next-redux-wrapper";
import { authSlice, getAuthSliceInitialState } from "./slices/authSlice";

// reduxはrtk-queryを動作させる為だけに使っています.
// useSWRを利用しない理由は、open-api自動生成でまともに動作するのがコミット時点では存在しない為です.
// 基本的に機能面では互換性があります.

const makeStore = (_: Context) => {
  const store = configureStore({
    reducer: {
      [nowdoApi.reducerPath]: nowdoApi.reducer,
      [authSlice.name]: authSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(nowdoApi.middleware),
  });
  setupListeners(store.dispatch);
  return store;
};

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore["getState"]>;
export type AppDispatch = AppStore["dispatch"];

export const wrapper = createWrapper(makeStore, {
  serializeState: (state) =>
    typeof window === "undefined"
      ? JSON.stringify({
          ...state,
          [authSlice.name]: {
            ...state[authSlice.name],
            fallbackToken: getAuthSliceInitialState().fallbackToken,
          },
        })
      : state,
  deserializeState: (state) => (typeof state === "string" ? JSON.parse(state) : state),
  // debug: true,
});

export const getInitialPageProps: (typeof wrapper)["getInitialPageProps"] = (f) => {
  const _getInitialPageProps = wrapper.getInitialPageProps(f);
  return async (ctx: NextPageContext) => {
    const props = await _getInitialPageProps?.(ctx);
    if (props.initialState) {
      const { initialState: _initialState, ...restProps } = props;
      return restProps;
    }
    return props;
  };
};
