import Link, { LinkProps } from "next/link";
import { useStore } from "~/hooks/useStore";
import { nowdoApi } from "~/hooks/rtk-query-api";
import { AppStore } from "~/store";
import { CustomPageLink } from "~/types/customPageLink";

export const loadClassroomsPageData = async (store: AppStore, maybeClassroomId?: unknown, maybeType?: unknown) => {
  const tasks: unknown[] = [
    store.dispatch(nowdoApi.endpoints.listCategories.initiate()),
    store.dispatch(nowdoApi.endpoints.listClassrooms.initiate({})),
  ];
  if (maybeType === "again" && typeof maybeClassroomId === "string") {
    tasks.push(store.dispatch(nowdoApi.endpoints.getClassroom.initiate({ id: maybeClassroomId })));
  }
  await Promise.all(tasks);
};

export const ClassroomsPageLink: CustomPageLink = ({ aProps = {}, children, ...linkProps }) => {
  const store = useStore();

  const props = {
    ...linkProps,
    ...aProps,
  } as Partial<LinkProps>;

  return (
    <Link
      {...props}
      href="/classrooms"
      onMouseEnter={() => aProps.target !== "_blank" && loadClassroomsPageData(store)}
    >
      {children}
    </Link>
  );
};
