import css from "./PageTitle.module.scss";
import { Image } from "~/components/Image";

type PageSubTitleProps = {
  sub: string;
  loading?: boolean;
};
export const PageSubTitle: React.FC<PageSubTitleProps> = ({ sub, loading = false }) => (
  <div className={css.sub}>
    <span className={loading ? "_loading_text" : ""}>{sub}</span>
  </div>
);

type PageTitleProps = React.PropsWithChildren<
  {
    title: string;
    emoji?: string | null;
    childrenWrapperProps?: Omit<React.ComponentProps<"div">, "children">;
  } & PageSubTitleProps
>;
export const PageTitle: React.FC<PageTitleProps> = ({
  title,
  sub,
  emoji = null,
  loading = false,
  childrenWrapperProps = {},
  children,
}) => (
  <>
    {emoji && <Image src={`/images/${emoji}`} width={140} height={140} alt="" />}
    <div className={css.title}>
      <div {...childrenWrapperProps}>
        <span className={loading ? "_loading_text" : ""}>{title}</span>
        {children}
      </div>
      <PageSubTitle sub={sub} loading={loading} />
    </div>
  </>
);
