import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthState = { fallbackToken: string | null };
type Nullish = null | undefined;
type NonNullable<T> = Exclude<T, Nullish>;

export const getAuthSliceInitialState = (): AuthState => ({
  fallbackToken: null,
});

// server sideでbaseQueryがcookieからtokenを取得する為に必要だった為に仕方なく実装したものです
export const authSlice = createSlice({
  name: "auth",
  initialState: getAuthSliceInitialState(),
  reducers: {
    setFallbackToken: (state, { payload: { fallbackToken } }: PayloadAction<NonNullable<AuthState>>) => {
      state.fallbackToken = fallbackToken;
    },
  },
});

export const { setFallbackToken } = authSlice.actions;
