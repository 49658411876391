function isNullish<T>(v: T): v is Exclude<T, null | undefined> {
  return v === null || v === undefined;
}

export function filterNullishEntry<T extends Record<string, any>>(obj: T): T {
  return Object.entries(obj).reduce((pre, [key, value]) => {
    if (isNullish(value)) {
      return pre;
    }
    pre[key as keyof T] = value;
    return pre;
  }, {} as T);
}
