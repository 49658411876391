import Link from "next/link";
import { useRouter } from "next/router";
import { useUser } from "context/userContext";
import { Image } from "~/components/Image";
import React, { useState } from "react";
import classnames from "classnames";
import { CreateClassroomModal } from "~/components/aud/ui/CreateClassroomModal";
import { canCreateClassroom } from "~/submodules/canClassroom";
import { useToast } from "~/utils";
import Logo from "../../../assets/images/logo_new.svg";
import BellIcon from "../../../assets/images/_bell.svg";
import BellUnreadIcon from "../../../assets/images/_bell_unread.svg";
import css from "./Header.module.scss";
import { ClassroomsPageLink } from "~/components/links/ClassroomsPageLink";
import { HomePageLink } from "~/components/links/HomePageLink";
import { ArchivesPageLink } from "~/components/links/ArchivesPageLink";
import { UserPageLink } from "~/components/links/UserPageLink";
import { useIsOpenCreateClassroomModal } from "../providers/IsOpenCreateClassroomModalProvider";
import { NotificationsLink } from "~/components/links/NotificationsLink";

type MenusProps = {
  withNotice?: boolean;
};

export const Header: React.FC<MenusProps> = ({ withNotice = false }) => {
  const router = useRouter();
  const toast = useToast();
  const { user, signOut } = useUser();
  const { isOpenCreateClassroom, setIsOpenCreateClassroom } = useIsOpenCreateClassroomModal();
  const [isNavOpened, setIsNavOpened] = useState(false);
  const under12Student = !user?.canCommunicateAge && user?.isStudentAge;

  const logout = async () => {
    await signOut();
    toast.success("ログアウトしました");
    await router.push("/");
  };

  const canUserCreateClassroom = user && canCreateClassroom(user);

  const globalLinks = (
    <>
      {!under12Student && (
        <>
          <div className={css.list}>
            <ClassroomsPageLink
              aProps={{
                className: classnames(css.link, {
                  [css.active]: router.pathname === "/classrooms",
                }),
                onClick: () => setIsNavOpened(false),
              }}
            >
              <span className={css.icon}>
                <Image src="/images/nav__classrooms.svg" width={24} height={24} alt="Go to classrooms page" />
              </span>
              <span className={css.label}>クラスルーム</span>
            </ClassroomsPageLink>
          </div>
          {canUserCreateClassroom && (
            <div className={classnames(css.list, "_only__sp")}>
              <a
                className={css.link}
                onClick={(e) => {
                  e.preventDefault();
                  setIsOpenCreateClassroom(true);
                }}
              >
                クラスルームを作成
              </a>
            </div>
          )}
        </>
      )}
      <div className={css.list}>
        <ArchivesPageLink
          aProps={{
            className: classnames(css.link, {
              [css.active]: router.pathname === "/archives",
            }),
            onClick: () => setIsNavOpened(false),
          }}
        >
          <span className={css.icon}>
            <Image src="/images/nav__archive.svg" width={24} height={24} alt="Go to archives page" />
          </span>
          <span className={css.label}>アーカイブ</span>
        </ArchivesPageLink>
      </div>
      <div className={css.list}>
        <Link
          href="/professionals"
          className={classnames(css.link, {
            [css.active]: router.pathname === "/professionals",
          })}
        >
          <span className={css.icon}>
            <Image src="/images/nav__professionals.svg" width={24} height={24} alt="Go to professionals page" />
          </span>
          <span className={css.label}>プロフェッショナル</span>
        </Link>
      </div>
      {!user?.isSubscribed && (
        <div className={css.list}>
          <Link
            href="/subscriptions/new"
            className={classnames(css.link, {
              [css.active]: router.pathname === "/subscriptions/new",
            })}
            onClick={() => setIsNavOpened(false)}
          >
            <span className={css.icon}>
              <Image src="/images/nav__nowdo.svg" width={24} height={24} alt="Let's subscribe" />
            </span>
            <span className={css.label}>NowDo プレミアム</span>
          </Link>
        </div>
      )}
    </>
  );

  const userLinks = (
    <>
      <div className={css.list}>
        {user && (
          <UserPageLink
            user={user}
            aProps={{
              className: css.link,
              onClick: () => setIsNavOpened(false),
              "data-test": "menu-profile-settings",
            }}
          >
            <span className={css.label}>マイプロフィール</span>
          </UserPageLink>
        )}
      </div>
      <div className={css.list}>
        <Link
          href="/profile_settings"
          className={css.link}
          onClick={() => setIsNavOpened(false)}
          data-test="menu-profile-settings"
        >
          <span className={css.icon}>
            <Image src="/images/nav__settings.svg" width={24} height={24} alt="Go to profile setting page" />
          </span>
          <span className={css.label}>プロフィール設定</span>
        </Link>
      </div>
      {user?.isProfessional && (
        <div className={css.list}>
          <Link
            href="/org/settings"
            className={css.link}
            onClick={() => setIsNavOpened(false)}
            data-test="menu-organization-settings"
          >
            <span className={css.label}>プロフェッショナル設定</span>
          </Link>
        </div>
      )}
      <div className={css.list}>
        <Link href="/settings" className={css.link} onClick={() => setIsNavOpened(false)} data-test="menu-settings">
          <span className={css.label}>アカウント設定</span>
        </Link>
      </div>
      <div className={css.list}>
        <a
          href="https://help.nowdo.in"
          target="_blank"
          rel="noreferrer"
          className={css.link}
          onClick={() => setIsNavOpened(false)}
        >
          <span className={css.icon}>
            <Image src="/images/nav__help.svg" width={24} height={24} alt="Go to help center" />
          </span>
          <span className={css.label}>ヘルプセンター</span>
        </a>
      </div>
      {user && user.email !== "" && (
        <div className={css.list}>
          <a
            href="#"
            className={css.link}
            data-test="menu-sign-out"
            onClick={(e) => {
              e.preventDefault();
              setIsNavOpened(false);
              return logout();
            }}
          >
            <span className={css.icon}>
              <Image src="/images/nav__logout.svg" width={24} height={24} alt="logout" />
            </span>
            <span className={css.label}>ログアウト</span>
          </a>
        </div>
      )}
    </>
  );

  const loginLinks = (
    <>
      <div className={css.list}>
        <Link
          href="/signin"
          className={classnames(css.link, {
            [css.active]: router.pathname === "/signin",
          })}
          onClick={() => setIsNavOpened(false)}
          data-test="menu-sign-in"
        >
          <span className={css.icon}>
            <Image src="/images/nav__identity.svg" width={24} height={24} alt="login" />
          </span>
          <span className={css.label}>ログイン</span>
        </Link>
      </div>
      <div className={css.list}>
        <Link
          href="/signup"
          className={classnames(css.link, {
            [css.active]: router.pathname === "/signup",
          })}
          onClick={() => setIsNavOpened(false)}
          data-test="menu-sign-up"
        >
          <span className={css.button}>NowDo に参加</span>
        </Link>
      </div>
    </>
  );
  const legalLinks = (
    <>
      <div className={css.list}>
        <Link
          href="/aud/terms_of_use"
          className={classnames(css.link, {
            [css.active]: router.pathname === "/aud/terms_of_use",
          })}
          onClick={() => setIsNavOpened(false)}
        >
          <span className={css.label}>利用規約</span>
        </Link>
      </div>
      <div className={css.list}>
        <Link
          href="/privacy_policy"
          className={classnames(css.link, {
            [css.active]: router.pathname === "/privacy_policy",
          })}
          onClick={() => setIsNavOpened(false)}
        >
          <span className={css.label}>プライバシーポリシー</span>
        </Link>
      </div>
      <div className={css.list}>
        <Link
          href="/law"
          className={classnames(css.link, {
            [css.active]: router.pathname === "/law",
          })}
          onClick={() => setIsNavOpened(false)}
        >
          <span className={css.label}>特定商取引法に基づく表示</span>
        </Link>
      </div>
      <div className={css.list}>
        <a
          href="https://nowdo.jp/"
          target="_blank"
          rel="noreferrer"
          className={css.link}
          onClick={() => setIsNavOpened(false)}
        >
          <span className={css.label}>運営会社</span>
        </a>
      </div>
      <div className={css.list}>
        <Link
          href="/brand_assets"
          className={classnames(css.link, {
            [css.active]: router.pathname === "/brand_assets",
          })}
          onClick={() => setIsNavOpened(false)}
        >
          <span className={css.label}>ロゴの利用について</span>
        </Link>
      </div>
    </>
  );

  return (
    <header
      className={classnames([
        css.header,
        {
          [css.withNotice]: withNotice,
        },
      ])}
    >
      <h1 className={css.logo}>
        <HomePageLink
          aProps={{
            onClick: () => setIsNavOpened(false),
          }}
        >
          <Logo aria-label="Go to top page" />
        </HomePageLink>
      </h1>
      <div className={css.leftMenu}>
        <nav className={css.nav}>{globalLinks}</nav>
      </div>
      <div className={css.rightMenu}>
        <nav className={css.nav}>
          {user ? (
            <>
              <NotificationsLink
                aProps={{
                  className: css.bellIcon,
                }}
              >
                {user.unreadNotificationCount ? <BellUnreadIcon /> : <BellIcon />}
              </NotificationsLink>
              {canUserCreateClassroom && (
                <a
                  className={classnames(["_button", "primary"])}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsOpenCreateClassroom(true);
                  }}
                >
                  クラスルームを作成
                </a>
              )}
              <div className={css.list}>
                <span className={css.link}>
                  <span
                    className={css.profileImage}
                    style={{
                      backgroundImage: `url(${user.avatarURL}?w=200&h=200)`,
                    }}
                  />
                  <span className={css.dropdown}>
                    <Image src="/images/arrow_drop_down.svg" width={12} height={6} alt="user menus" />
                  </span>
                </span>
                <div className={css.subnav}>{userLinks}</div>
              </div>
            </>
          ) : (
            loginLinks
          )}
        </nav>
      </div>
      <div className={classnames(css.mask, { [css.open]: isNavOpened })} onClick={() => setIsNavOpened(false)} />
      <div className={classnames(css.spNav, { [css.open]: isNavOpened })} id="spNav">
        <div className={css.section}>{globalLinks}</div>
        <div className={css.section}>{user ? userLinks : loginLinks}</div>
        <div className={css.section}>{legalLinks}</div>
      </div>
      <div className={css.spMenu}>
        {!isNavOpened && (
          <NotificationsLink
            aProps={{
              className: classnames([css.bellIcon, css.spBellIcon]),
            }}
          >
            {user?.unreadNotificationCount ? <BellUnreadIcon /> : <BellIcon />}
          </NotificationsLink>
        )}
        <span className={css.menuButton}>
          {isNavOpened ? (
            <Image
              src="/images/header__nav__menu_close.svg"
              width={24}
              height={21}
              onClick={() => setIsNavOpened(false)}
              alt="close menu"
            />
          ) : (
            <Image
              src="/images/header__nav__menu.svg"
              width={24}
              height={21}
              onClick={() => setIsNavOpened(true)}
              alt="open menu"
            />
          )}
        </span>
      </div>
      {user && (
        <CreateClassroomModal
          open={isOpenCreateClassroom}
          onClose={() => setIsOpenCreateClassroom(false)}
          user={user}
          key={user.id}
        />
      )}
    </header>
  );
};
