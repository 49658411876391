import { ClassroomState } from "~/types/classroom";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { firestore } from "~/firebase";
import { apiTagTypes, NowDoEndpointBuilder } from "~/store/baseApi";

export const getFirestoreClassroomBuilder = (build: NowDoEndpointBuilder) => ({
  getFirestoreClassroom: build.query<FirestoreClassroomData | null, { classroomId: string }>({
    providesTags: [apiTagTypes["All"]],
    queryFn: () => ({
      data: null,
    }),
    async onCacheEntryAdded(arg, { updateCachedData, cacheEntryRemoved }) {
      const unsubscribe = onSnapshot(
        doc(collection(firestore, "rooms"), arg.classroomId),
        { includeMetadataChanges: true },
        (snapshot) => {
          const data = snapshot.data() as FirestoreClassroomData;
          updateCachedData(() => data);
        }
      );
      await cacheEntryRemoved;
      unsubscribe();
    },
  }),
});

type FirestoreClassroomData = {
  userID: string;
  name: string;
  imageURL: string;
  usersCount: number;
  description: string;
  allUsers: boolean;
  audienceMode: boolean;
} & (
  | {
      state: ClassroomState;
      startAt: number;
      endAt: number;
      reservationUserCount: number;
    }
  | {
      state: null;
      startAt: null;
      endAt: null;
      reservationUserCount: null;
    }
);
