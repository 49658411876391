import React from "react";
import classnames from "classnames";
import css from "./Body.module.scss";
import { SimpleBarPatch } from "~/hoc/SimpleBarPatch";

type BodyProps = React.PropsWithChildren<{
  withHeader?: boolean;
  withNotice?: boolean;
  emptyLayout?: boolean;
  blackLayout?: boolean;
  darkLayout?: boolean;
  onlyContent?: boolean;
  noPadding?: boolean;
  noMargin?: boolean;
}>;

export const Body: React.FC<BodyProps> = SimpleBarPatch(
  ({
    children,
    withHeader = false,
    withNotice = false,
    emptyLayout = false,
    blackLayout = false,
    darkLayout = false,
    onlyContent = false,
    noPadding = false,
    noMargin = false,
  }) => (
    <div
      className={classnames([
        {
          [css.emptyLayout]: emptyLayout,
          [css.blackLayout]: blackLayout,
          [css.darkLayout]: darkLayout,
          [css.body]: !(emptyLayout && blackLayout && darkLayout),
          [css.withHeader]: withHeader,
          [css.withNotice]: withNotice,
          [css.onlyContent]: onlyContent,
          [css.noPadding]: noPadding,
          [css.noMargin]: noMargin,
        },
      ])}
    >
      {children}
    </div>
  )
);
