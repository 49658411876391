import { Buffer } from "buffer";
import { setCookie, destroyCookie, parseCookies } from "nookies";

export const getPayload = (token: string): { sub: string; jti: string; anonymous?: boolean } | undefined => {
  const parts = token.split(".");
  if (parts.length !== 3) {
    return;
  }
  const d = Buffer.from(parts[1], "base64").toString("utf-8");
  return JSON.parse(d);
};

export const getAuthToken = (ctx?: any): string | undefined => {
  return parseCookies(ctx)["token"];
};

export const setAuthToken = (ctx: any, token: string) => {
  setCookie(ctx, "token", token, {
    path: "/",
    maxAge: 30 * 24 * 60 * 60,
    secure: true,
  });
};

export const removeAuthToken = (ctx?: any) => {
  destroyCookie(ctx, "token");
};
