import React, { useState, useEffect, createContext, useContext, useCallback, useMemo } from "react";
import * as Sentry from "@sentry/browser";
import { nowdoApi, useGetMeQuery, useSignOutMutation } from "~/hooks/rtk-query-api";
import { useStore } from "~/hooks/useStore";
import { Me } from "~/generated/rtk-query/nowdo-live-api.generated";
import { getAuthToken } from "~/authtoken";
import axios from "axios";
import { AppStore } from "~/store";
import { apiTagTypes } from "~/store/baseApi";

export type User = {
  id: string;
  email: string;
  avatarURL: string;
  state: string;
  hasOrgProfile: boolean;
  isStudentAge: boolean;
  canCommunicateAge: boolean;
  isProfessional: boolean;
  under12Student: boolean;
  isAdult: boolean;
  isSubscribed: boolean;
  willUnsubscribed: boolean;
  isPartnerSchoolStudent: boolean;
  stripeSubscriptionExpiresAt: number;
  stripeInvoiceStatus: string;
  nickname: string;
  introduction: string;
  job: string;
  unreadNotificationCount: number;
};

type ResetUser = (me: Me) => User;

interface UserContextValue {
  user: User | null;
  setUser: any;
  loadingUser: boolean;
  resetUser: ResetUser;
  refetchUser: () => void;
  init: () => void;
  signOut: () => Promise<unknown>;
  isAnonymous: boolean;
}

export const UserContext = createContext<UserContextValue | null>(null);

const getUser: ResetUser = (me) => ({
  unreadNotificationCount: me.unreadNotificationCount || 0,
  id: me.id,
  email: me.email,
  avatarURL: me.avatarUrl,
  state: me.state,
  hasOrgProfile: me.isProfessional,
  isStudentAge: me.isStudentAge,
  canCommunicateAge: me.canCommunicateAge,
  isProfessional: me.isProfessional,
  isPartnerSchoolStudent: me.isPartnerSchoolStudent,
  isSubscribed: me.isSubscribed,
  under12Student: !me.canCommunicateAge && me.isStudentAge,
  isAdult: !me.isStudentAge,
  willUnsubscribed: me.willUnsubscribed,
  stripeSubscriptionExpiresAt: me.subscriptionExpiresAt,
  stripeInvoiceStatus: me.stripeInvoiceStatus,
  nickname: me.nickname,
  introduction: me.introduction,
  job: me.job ?? "",
});

export const UserProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const me = useGetMeQuery();
  const [signOutMutation] = useSignOutMutation();
  const [user, setUser] = useState<User | null>(me.data ? getUser(me.data) : null);
  const store: AppStore = useStore();

  const resetUser: ResetUser = (userFromNowdoApi) => {
    const u = getUser(userFromNowdoApi);
    setUser(u);
    return u;
  };

  const refetchUser = useCallback(() => {
    store.dispatch(nowdoApi.util.invalidateTags([apiTagTypes.User]));
  }, []);

  const init = useCallback(() => {
    setUser(null);
  }, []);

  const signOut = useCallback(async () => {
    const token = getAuthToken();
    if (!token) {
      return;
    }
    await signOutMutation().unwrap();
    await axios.delete("/api/signout");
    store.dispatch(nowdoApi.util.resetApiState());
    init();
  }, []);

  useEffect(() => {
    if (me.data) {
      resetUser(me.data);
    }
  }, [me.data]);

  useEffect(() => {
    if (user?.id) {
      Sentry.setUser({ id: user.id });
    }
  }, [user?.id]);

  const ctxValue = useMemo(
    (): UserContextValue => ({
      user,
      setUser,
      loadingUser: me.isLoading,
      resetUser,
      refetchUser,
      signOut,
      init,
      isAnonymous: !me.isUninitialized && !me.isLoading && !user,
    }),
    [user, me.isLoading]
  );

  return <UserContext.Provider value={ctxValue}>{children}</UserContext.Provider>;
};

// Custom hook that shorhands the context!
export const useUser = () => {
  const context = useContext(UserContext);
  if (context === null) {
    throw Error("required provide user");
  }
  return context;
};
