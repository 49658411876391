import NextImage, { ImageProps } from "next/image";

function loader({ src, width, quality }): string {
  return `${src}?w=${width}&q=${quality || 80}`;
}

export const Image: React.FC<ImageProps> = (props) => {
  if (props.src) {
    return <NextImage loader={loader} {...props} />;
  }
  return null;
};
