import axios, { AxiosRequestConfig } from "axios";
import { authorizationHeader } from "./submodules/http/authorizationHeader";
import { filterNullishEntry } from "./submodules/filterNullishEntry";
import { ClassroomComment } from "~/types/classroom";

const axiosConfig: AxiosRequestConfig = {
  baseURL: `${process.env.NEXT_PUBLIC_API_ENDPOINT}`,
};
const api = axios.create(axiosConfig);

/**
 * User
 */
export const resendEmailVerification = (token: string) =>
  api.post("/v1/email_verification", {}, authorizationHeader(token));

/**
 * Public
 */

export type TrialCoupon = {
  id: string;
  expired_at: number;
  limit_count: number;
  used_count: number;
  trial_period_days: number;
  created_at: number;
  updated_at: number;
};
export const checkTrialCoupon = (data: { code: string }) =>
  api.post<{
    trial_coupon: TrialCoupon;
    valid: boolean;
  }>("/v1/check_trial_coupon", data);

// webからだとagora_user_idをnumberで取得できないみたいなので、設定しないで送る仕様になりました
// 設定しないで送ったらwebhookでgolangが設定してくれます
export const generateClassroomVideoToken = async (token: string, roomId: string): Promise<string> => {
  const response = await api.get<{ token: string }>(
    `/v1/classrooms/${roomId}/generate_video_token`,
    authorizationHeader(token)
  );
  return response.data.token;
};

export const generateClassroomCameraToken = async (token: string, roomId: string): Promise<string> => {
  const response = await api.get<{ token: string }>(
    `/v1/classrooms/${roomId}/generate_camera_token`,
    authorizationHeader(token)
  );
  return response.data.token;
};

export const reactionNameEnum = {
  heart: "heart",
  "+1": "+1",
  hand: "hand",
  clap: "clap",
  double: "double",
} as const;
export type ReactionName = keyof typeof reactionNameEnum;

export type ClassroomCommentsOrder = "asc" | "desc";
export type ClassroomCommentsQuery = Partial<{
  limit: number;
  cursor: number;
  order: ClassroomCommentsOrder;
}>;

export interface ClassroomCommentsData {
  comments: ClassroomComment[];
  cursor: number;
}

export const classroomComments = (token: string, roomId: string, query: ClassroomCommentsQuery) =>
  api.get<ClassroomCommentsData>(`/v1/classrooms/${roomId}/comments`, {
    ...authorizationHeader(token),
    params: filterNullishEntry(query),
  });
