import classnames from "classnames";

type FormSubmitButton = {
  children: React.ReactNode;
  size: string;
  test?: string;
  disabled?: boolean;
  loading?: boolean;
  classes?: string[];
};

export const FormSubmitButton: React.FC<FormSubmitButton> = ({
  children,
  loading,
  size,
  disabled = false,
  test = "input-submit",
  classes = [],
}) => {
  const className = classnames(
    {
      loading,
      disabled,
    },
    "_button",
    "primary",
    size,
    ...classes
  );
  return (
    <button type="submit" className={className} disabled={loading || disabled} data-test={test}>
      {children}
      <div className="_loader">Loading...</div>
    </button>
  );
};

FormSubmitButton.defaultProps = {
  size: "medium",
  classes: [],
};
