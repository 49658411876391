import { useEffect } from "react";
import { Router } from "next/router";
import { UserProvider } from "context/userContext";
import { AudiencePageLayout } from "layouts/AudiencePageLayout";
import * as gtag from "../components/GoogleAnalytics";
import "assets/stylesheets/global.scss";
import "notyf/notyf.min.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import App from "next/app";
import { wrapper } from "~/store";
import { nowdoApi } from "~/hooks/rtk-query-api";
import { parseCookies } from "nookies";
import { setFallbackToken } from "~/store/slices/authSlice";
import NextNProgress from "nextjs-progressbar";
import { AppPropsWithLayout } from "next/app";

const AppComponent = ({ Component, pageProps, err }: AppPropsWithLayout) => {
  const Layout = Component.Layout || AudiencePageLayout;

  useEffect(() => {
    Router.events.on("routeChangeComplete", (url) => {
      gtag.pageview(url);
      window.scrollTo(0, 0);
    });
  }, []);

  return (
    <>
      <NextNProgress color="#FF4444" options={{ showSpinner: false }} />
      <UserProvider>
        <Layout>
          <Component {...pageProps} err={err} />
        </Layout>
      </UserProvider>
    </>
  );
};

AppComponent.getInitialProps = wrapper.getInitialAppProps((store) => async (appContext) => {
  const token = parseCookies(appContext["ctx"])["token"];
  if (!token) {
    return {
      pageProps: (await App.getInitialProps(appContext)).pageProps,
    };
  }
  store.dispatch(setFallbackToken({ fallbackToken: token }));

  try {
    await store.dispatch(nowdoApi.endpoints.getMe.initiate());
    // eslint-disable-next-line no-empty
  } catch {}

  const { pageProps } = await App.getInitialProps(appContext);

  store.dispatch(setFallbackToken({ fallbackToken: null }));

  return {
    pageProps,
  };
});

export default wrapper.withRedux(AppComponent);
