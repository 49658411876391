import { User } from "~/context/userContext";
import { Classroom } from "~/generated/rtk-query/nowdo-live-api.generated";

export const canJoinTargetClassroom = (classroom: Classroom, user: User | null): boolean => {
  if (user && user.isStudentAge && !user.canCommunicateAge) {
    return false;
  }

  if (classroom.allUsers) {
    return true;
  }

  if (!user) {
    return false;
  }

  if (user.isProfessional) {
    return true;
  }

  return !user.isAdult;
};

export const canCreateClassroom = (user: User | null): boolean => {
  if (!user) {
    return false;
  }
  return !user.under12Student;
};
