import Link, { LinkProps } from "next/link";
import { useStore } from "~/hooks/useStore";
import { nowdoApi } from "~/hooks/rtk-query-api";
import { AppStore } from "~/store";
import { CustomPageLink } from "~/types/customPageLink";
import { ClassroomUser } from "~/generated/rtk-query/nowdo-live-api.generated";
import css from "./Link.module.scss";
import classnames from "classnames";

export const defaultListUserArchivesLimit = 50;

export const loadUserPageData = async (store: AppStore, userId: string) => {
  await Promise.all([
    store.dispatch(nowdoApi.endpoints.getUser.initiate({ id: userId })).unwrap(),
    store.dispatch(nowdoApi.endpoints.listClassrooms.initiate({ userId })).unwrap(),
    store
      .dispatch(
        nowdoApi.endpoints.listUserArchives.initiate({
          id: userId,
          limit: defaultListUserArchivesLimit,
        })
      )
      .unwrap(),
    store.dispatch(nowdoApi.endpoints.listCategories.initiate()).unwrap(),
  ]);
};

export const UserPageLink: CustomPageLink<{
  user: Pick<ClassroomUser, "id"> & Partial<Pick<ClassroomUser, "role">>;
}> = ({ aProps = {}, user, children, ...linkProps }) => {
  const store = useStore();

  if (user.role === "anonymous") {
    return <>{children}</>;
  }

  const props = {
    ...linkProps,
    ...aProps,
  } as Partial<LinkProps>;

  return (
    <Link
      {...props}
      href={`/users/${user.id}`}
      className={classnames([aProps.className, css.aTag])}
      onMouseEnter={() => aProps.target !== "_blank" && loadUserPageData(store, user.id)}
    >
      {children}
    </Link>
  );
};
