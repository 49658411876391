/* eslint-disable react-hooks/rules-of-hooks */

import classnames from "classnames";
import { useEffect, useState } from "react";
import css from "./SimpleBarPatch.module.scss";

export function SimpleBarPatch<P>(Component: React.ComponentType<P>, className?: string) {
  const fc: React.VFC<P> = (props: P, ...args) => {
    const [isReadyPatchSimplebar, setIsReadyPatchSimplebar] = useState(true);

    useEffect(() => {
      if (typeof window !== "undefined") {
        setIsReadyPatchSimplebar(false);
      }
    }, []);

    return (
      <div
        className={classnames([
          {
            [css.simpleBarPatch]: isReadyPatchSimplebar,
          },
          className,
        ])}
      >
        <Component {...args} {...props} />
      </div>
    );
  };

  return fc;
}
