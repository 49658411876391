import { ListUserArchivesApiResponse, nowdoApiBase } from "generated/rtk-query/nowdo-live-api.generated";
import { apiTagTypes } from "~/store/baseApi";
import { getFirestoreClassroomBuilder } from "./firestoreQuery/getFirestoreClassroomBuilder";
import { listFirestoreClassroomUsersBuilder } from "./firestoreQuery/listFirestoreClassroomUsersBuilder";

const notificationTypes = [
  "management",
  "eventStartBeforeFiveMinutes",
  "eventStartBeforeOneHour",
  "eventArchived",
  "eventOpen",
  "userFollow",
  "classroomCreatedByFollow",
  "classroomReservedByFollow",
  "reservedClassroomStarted",
  "reservedClassroomCancelled",
  "changeClassroomTime",
] as const;

export function getNotificationTypes(type: number) {
  return notificationTypes[type];
}

// タグ付けは手動です.
// 以前rtk-query-codegenの計画ではswaggerにタグを付け、そこから反映する計画もあったけど、今どうなってるかは未確認
export const nowdoApi = nowdoApiBase
  .injectEndpoints({
    endpoints: (build) => ({
      ...getFirestoreClassroomBuilder(build),
      ...listFirestoreClassroomUsersBuilder(build),
      readListMeNotifications: build.mutation<null, void>({
        queryFn: () => ({ data: null }),
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            nowdoApi.util.updateQueryData("listMeNotifications", {}, (draft) => {
              draft.data.forEach((v) => (v.read = true));
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      }),
    }),
  })
  .enhanceEndpoints({
    endpoints: {
      getAppSubscriptionPlan: {
        providesTags: [apiTagTypes["All"]],
      },
      listArchives: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Archive"]],
      },
      getArchive: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Archive"]],
      },
      listRecommendArchives: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Archive"]],
      },
      listCategories: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Category"]],
      },
      listClassrooms: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Classroom"]],
      },
      prepareClassroom: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Classroom"]],
      },
      getClassroom: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Classroom"]],
      },
      listClassroomComments: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Classroom"], apiTagTypes["ClassroomComment"]],
      },
      getClassroomCameraToken: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Classroom"]],
      },
      getClassroomToken: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Classroom"]],
      },
      getClassroomVideoToken: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Classroom"]],
      },
      getClassroomLink: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Classroom"]],
      },
      getClassroomShare: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Classroom"]],
      },
      listEvents: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Event"]],
      },
      getEvent: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Event"]],
      },
      getMe: {
        providesTags: [apiTagTypes["All"]],
        extraOptions: { maxRetries: 0 },
      },
      listMeFollowing: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Follow"]],
        extraOptions: { maxRetries: 0 },
      },
      listAudEvents: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Event"]],
      },
      getAudEvent: {
        providesTags: [apiTagTypes["All"], apiTagTypes["Event"]],
      },
      getNotification: {
        providesTags: [apiTagTypes["All"]],
      },
      listMeNotifications: {
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            nowdoApi.util.updateQueryData("getMe", undefined, (draft) => {
              if (draft) {
                draft.unreadNotificationCount = 0;
              }
            })
          );

          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      },
      listProfessionals: {
        providesTags: [apiTagTypes["All"]],
      },
      listSubscriptionPlans: {
        providesTags: [apiTagTypes["All"]],
      },
      getUser: {
        providesTags: [apiTagTypes["All"], apiTagTypes["User"]],
      },
      listUserArchives: {
        providesTags: [apiTagTypes["All"], apiTagTypes["User"], apiTagTypes["Archive"]],
        transformResponse: (response: ListUserArchivesApiResponse) => ({
          ...response,
          archives: [...response.archives].sort((a, b) => {
            if (a.watchCount < b.watchCount) return 1;
            if (a.watchCount > b.watchCount) return -1;
            return 0;
          }),
        }),
      },
      listUserFollowers: {
        providesTags: [apiTagTypes["All"], apiTagTypes["User"]],
      },
      listUserFollowing: {
        providesTags: [apiTagTypes["All"], apiTagTypes["User"], apiTagTypes["Follow"]],
      },
      watchArchive: { invalidatesTags: [apiTagTypes["Archive"]] },
      createClassroom: {
        invalidatesTags: [apiTagTypes["Classroom"]],
        extraOptions: { maxRetries: 0 },
      },
      updateClassroom: {
        invalidatesTags: [apiTagTypes["Classroom"]],
        extraOptions: { maxRetries: 0 },
      },
      deleteClassroom: { invalidatesTags: [apiTagTypes["Classroom"]] },
      createClassroomComment: {
        invalidatesTags: [apiTagTypes["ClassroomComment"]],
        extraOptions: { maxRetries: 0 },
      },
      deleteClassroomComment: {
        invalidatesTags: [apiTagTypes["ClassroomComment"]],
      },
      reserveClassroom: { invalidatesTags: [apiTagTypes["Classroom"]] },
      cancelClassroom: { invalidatesTags: [apiTagTypes["Classroom"]] },
      reportClassroomComment: {
        invalidatesTags: [apiTagTypes["ClassroomComment"]],
      },
      leaveClassroom: { invalidatesTags: [apiTagTypes["Classroom"]] },
      updateMe: { invalidatesTags: [apiTagTypes["All"]] },
      deleteMe: { invalidatesTags: [apiTagTypes["All"]] },
      createMeSubscription: { invalidatesTags: [apiTagTypes["All"]] },
      participateEventPremium: { invalidatesTags: [apiTagTypes["Event"]] },
      participateEventStudent: { invalidatesTags: [apiTagTypes["Event"]] },
      acceptEventInvitation: { invalidatesTags: [apiTagTypes["Event"]] },
      participateEvent: { invalidatesTags: [apiTagTypes["Event"]] },
      createEventUserPaymentIntent: { invalidatesTags: [apiTagTypes["All"]] },
      createSubscription: { invalidatesTags: [apiTagTypes["All"]] },
      signUpWithProfessional: {
        invalidatesTags: [apiTagTypes["All"], apiTagTypes["Auth"]],
      },
      signIn: {},
      signInWithPartnerSchool: {
        invalidatesTags: [apiTagTypes["All"], apiTagTypes["Auth"]],
      },
      signInAnonymous: {
        invalidatesTags: [apiTagTypes["All"]],
        extraOptions: { maxRetries: 0 },
      },
      signUpWithPartnerSchool: {
        invalidatesTags: [apiTagTypes["All"], apiTagTypes["Auth"]],
      },
      signUp: {
        extraOptions: { maxRetries: 0 },
      },
      unfollowUser: {
        invalidatesTags: [apiTagTypes["Follow"]],
        async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            nowdoApi.util.updateQueryData("getUser", { id }, (draft) => {
              draft.followerCount--;
              draft.isFollowed = false;
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      },
      followUser: {
        invalidatesTags: [apiTagTypes["Follow"]],
        async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            nowdoApi.util.updateQueryData("getUser", { id }, (draft) => {
              draft.followerCount++;
              draft.isFollowed = true;
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      },
      reactClassroom: {
        async onQueryStarted({ id, reactClassroomInput: { reactionName } }, { dispatch, queryFulfilled, getState }) {
          const userQuery = nowdoApi.endpoints.getMe.select()(getState());
          if (!userQuery.data) {
            return;
          }
          const myId = userQuery.data.id;

          const patchResult = dispatch(
            nowdoApi.util.updateQueryData("listFirestoreClassroomUsers", { classroomId: id }, (draft) => {
              const user = draft.find((u) => u.id === myId);
              if (user) {
                user.reactionName = reactionName;
              }
            })
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
      },
      verifyEmail: {
        invalidatesTags: [apiTagTypes["Auth"]],
      },
    },
  });

export const {
  usePrepareClassroomQuery,
  useGetAppSubscriptionPlanQuery,
  useListArchivesQuery,
  useListCategoryArchivesQuery,
  useListMeNotificationSettingsQuery,
  useListMeNotificationsQuery,
  useReadListMeNotificationsMutation,
  useReadNotificationMutation,
  useGetArchiveQuery,
  useListRecommendArchivesQuery,
  useListCategoriesQuery,
  useListClassroomsQuery,
  useGetClassroomQuery,
  useListClassroomCommentsQuery,
  useGetClassroomCameraTokenQuery,
  useGetClassroomTokenQuery,
  useGetClassroomVideoTokenQuery,
  useGetClassroomLinkQuery,
  useGetClassroomShareQuery,
  useListEventsQuery,
  useGetEventQuery,
  useGetMeQuery,
  useListMeFollowingQuery,
  useListAudEventsQuery,
  useGetAudEventQuery,
  useGetNotificationQuery,
  useListProfessionalsQuery,
  useListSubscriptionPlansQuery,
  useGetMeSubscriptionTrialQuery,
  useGetUserQuery,
  useListUserArchivesQuery,
  useListUserFollowersQuery,
  useListUserFollowingQuery,
  useWatchArchiveMutation,
  useCreateClassroomMutation,
  useUpdateClassroomMutation,
  useDeleteClassroomMutation,
  useCreateClassroomCommentMutation,
  useDeleteClassroomCommentMutation,
  useJoinClassroomMutation,
  useLeaveClassroomMutation,
  useMuteClassroomVoiceMutation,
  useReactClassroomMutation,
  useKickClassroomUserMutation,
  useUpdateClassroomUserRoleMutation,
  useUpdateMeMutation,
  useDeleteMeMutation,
  useCreateMeSubscriptionMutation,
  useCreateSubscriptionMutation,
  useResetPasswordMutation,
  useSendResetPasswordLinkMutation,
  useSignOutMutation,
  useSignUpWithProfessionalMutation,
  useSignUpWithPartnerSchoolMutation,
  useSignInMutation,
  useSignInWithPartnerSchoolMutation,
  useSignInAnonymousMutation,
  useSignUpMutation,
  useFollowUserMutation,
  useUnfollowUserMutation,
  useUpdateSubscriptionMutation,
  useDeleteSubscriptionMutation,
  useListEventArchivesQuery,
  useReserveClassroomMutation,
  useCancelClassroomMutation,
  useGetFirestoreClassroomQuery,
  useListFirestoreClassroomUsersQuery,
  useVerifyEmailMutation,
  useVerifyTrialCouponMutation,

  // stripe
  useGetStripeConnectQuery,
  useGetMeStripeCardQuery,
  useCreateStripeConnectMutation,
  useListMeSaleHistoriesQuery,
  useTransferToStripeAccountMutation,
} = nowdoApi;
