import ScrollContainer from "react-perfect-scrollbar";
import ModalCloseIcon from "../assets/images/_modal__close.svg";
import Link from "next/link";
import { useRouter } from "next/router";
import { useState } from "react";
import { useToast } from "utils";
import { resendEmailVerification } from "~/api";
import { parseCookies } from "nookies";

export const ResendVerificationEmailModal = ({ open, onClose, user }) => {
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const router = useRouter();

  const resendVerificationEmail = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = parseCookies({})["token"];

      await resendEmailVerification(token);
      toast.success("確認用メールを再送信しました。");
      setLoading(false);
      onClose();
    } catch (e) {
      const { response } = e;
      if (response.status == 400) {
        toast.error("リクエスト数が多すぎます。時間をあけてから再度お試しください。");
      } else {
        console.error(e);
        toast.error("エラーが発生しました。お手数ですがもう一度時間を開けておためしください。");
      }
      setLoading(false);
    }
  };

  return (
    <div className={"_modal__wrapper wide " + (open ? "show" : "")}>
      <ScrollContainer>
        <div className="_modal">
          <div className="content">
            <h2>確認用メールを再送信する</h2>
            <p>
              以下のメールアドレスに確認用メールを再送信します。もしメールアドレスが間違っている場合は{" "}
              <Link href="/settings">設定画面</Link>
              からメールアドレスの再設定をお願いします。
            </p>
            <p>
              メールアドレス: <br className="_only__sp" />
              <b>{user.email}</b>
            </p>
          </div>
          <div className="footer right">
            <a
              className="_button"
              onClick={(e) => {
                e.preventDefault();
                router.push("/settings");
                onClose();
              }}
            >
              設定画面へ
            </a>
            <a className={"_button red" + (loading ? " loading" : "")} onClick={resendVerificationEmail}>
              <div className="_loader">loading</div>
              メールを再送信
            </a>
          </div>
          <div className="_modal__close" onClick={onClose}>
            <ModalCloseIcon />
          </div>
        </div>
      </ScrollContainer>
    </div>
  );
};
