import { useState } from "react";
import ScrollContainer from "react-perfect-scrollbar";
import ModalCloseIcon from "~/assets/images/_modal__close.svg";
import { PageTitle } from "~/components/aud/ui/PageTitle";
import classnames from "classnames";
import DatePicker from "react-datepicker";
import css from "./CreateClassroomModal.module.scss";
import { FormSubmitButton } from "~/components/FormSubmitButton";
import { useToast } from "utils";
import { Controller, FieldError, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { getUnixTime } from "date-fns";
import { useRouter } from "next/router";
import { addDays } from "date-fns";
import { User } from "~/context/userContext";
import { useCreateClassroomMutation, useListCategoriesQuery } from "~/hooks/rtk-query-api";
import { Category, CreateClassroomInput } from "~/generated/rtk-query/nowdo-live-api.generated";
import { isNullish } from "~/submodules/isNullish";

type Form = {
  name: string;
  description: string;
  categoryId: string;
  startAt: Date;
  allUsers: boolean;
  audienceMode: boolean;
  privateMode: boolean;
};

type CreateClassroomModalProps = {
  open: boolean;
  user: User;
  onClose: () => unknown;
  onNo?: () => unknown;
  defaultValues?: Partial<Form>;
};

export const CreateClassroomModal: React.FC<CreateClassroomModalProps> = (props) => {
  const { data } = useListCategoriesQuery();

  if (isNullish(data) || data?.categories.length === 0 || !props.open) {
    return null;
  }

  return <CreateClassroomModalBase {...props} categoryList={data.categories} />;
};

export const CreateClassroomModalBase: React.FC<
  CreateClassroomModalProps & {
    categoryList: Category[];
  }
> = ({ user, onClose, onNo, categoryList, defaultValues = {} }) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  const minDate = new Date();
  const maxDate = addDays(new Date(), 30);
  const [createClassroom] = useCreateClassroomMutation();

  // プロフェッショナルじゃない大人が作成する部屋は強制的に誰でも参加させます
  const requiredAllUsersParticipate = !user.isProfessional && user.isAdult;

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      categoryId: categoryList[0].id,
      startAt: new Date(),
      allUsers: requiredAllUsersParticipate,
      audienceMode: false,
      privateMode: false,
      ...defaultValues,
    },
  });

  const onSubmitClassroom = async (data: Form) => {
    setLoading(true);
    try {
      const params: CreateClassroomInput = {
        name: data.name,
        description: data.description,
        categoryId: data.categoryId || categoryList[0].id,
        startAt: getUnixTime(data.startAt),
        minutes: 45,
        allUsers: requiredAllUsersParticipate || data.allUsers,
        interestIds: [],
        audienceMode: data.audienceMode,
        privateMode: data.privateMode,
      };

      const res = await createClassroom({
        createClassroomInput: params,
      }).unwrap();
      await router.push(`/classrooms/${res.id}`);

      toast.success("クラスルームを作成しました");
      onClose();
    } catch (e: any) {
      if (e.data?.error && e.status === 422) {
        toast.error(e.data?.error);
        return;
      }
      if (e.data?.error) {
        toast.error("エラーが発生しました。お手数ですがもう一度時間を開けておためしください。");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="_modal__wrapper show">
      <ScrollContainer>
        <div className="_modal wide">
          <div className="content">
            <PageTitle title="Create a Room" sub="クラスルームを作成" />
            <form onSubmit={handleSubmit(onSubmitClassroom)}>
              <div className={classnames("_form", css.form)}>
                <div className={css.field}>
                  <label>
                    <span className="label">クラスルームのタイトル</span>
                    <input
                      type="text"
                      placeholder="プログラミング勉強会"
                      {...register("name", { required: true, maxLength: 50 })}
                    />
                    {errors.name && (
                      <span className="sub red">
                        {errors.name.type === "maxLength"
                          ? "クラスルームのタイトルは50文字以内で入力ください。"
                          : "クラスルームのタイトルを入力してください。"}
                        <br />
                      </span>
                    )}
                    <span className="sub">タイトルは 50 文字以内で入力してください</span>
                  </label>
                </div>
                <div className={css.field}>
                  <label>
                    <input type="checkbox" disabled={requiredAllUsersParticipate} {...register("allUsers")} />
                    <div className={css.inline}>
                      <span>だれでも参加OK</span>
                      <br />
                      <span className="sub">25 歳以上や Web からログインなしで参加できます</span>
                    </div>
                  </label>
                </div>
                <div className={css.field}>
                  <label>
                    <input type="checkbox" {...register("audienceMode")} />
                    <div className={css.inline}>
                      <span>オーディエンスモード</span>
                      <br />
                      <span className="sub">主催者が承認した参加者のみ発言できます</span>
                    </div>
                  </label>
                </div>
                <div className={css.field}>
                  <label>
                    <input type="checkbox" {...register("privateMode")} />
                    <div className={css.inline}>
                      <span>非公開クラスルームにする</span>
                      <br />
                      <span className="sub">URL を知っている人のみ参加できます</span>
                    </div>
                  </label>
                </div>
                <div className={css.field}>
                  <label>
                    <span className="label">開始時間</span>
                    <Controller
                      {...register("startAt", { required: true })}
                      control={control}
                      name="startAt"
                      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                        <>
                          <DatePicker
                            ref={ref}
                            value={value as any}
                            dateFormat="yyyy/MM/dd HH:mm"
                            onChange={(date: Date) => onChange(date)}
                            minDate={minDate as Date}
                            maxDate={maxDate as Date}
                            selected={value as Date}
                            showTimeInput
                          />
                          {(error as FieldError)?.type === "required" && (
                            <span className="sub red">
                              開始時間を入力してください。
                              <br />
                            </span>
                          )}
                        </>
                      )}
                    />
                    <span className="sub">開始時間になったら参加者に通知されます</span>
                  </label>
                </div>
                <div className={css.field}>
                  <label>
                    <span className="label">カテゴリー</span>
                    <select {...register("categoryId", { required: true })}>
                      {categoryList.map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </label>
                </div>
                <div className={css.field}>
                  <label>
                    <span className="label">クラスルームの概要</span>
                    <textarea
                      {...register("description")}
                      placeholder="プログラミングについて学びたい人のためのルームです。思いやりのある言葉でやりとりをしてください。"
                    />
                  </label>
                  <span className="sub">ルームの目的や、ルーム利用時の注意事項などを書いてください</span>
                </div>
              </div>
              <p>
                <FormSubmitButton size="medium" loading={loading}>
                  作成する
                </FormSubmitButton>
              </p>
            </form>
            <div
              className="_modal__close"
              onClick={() => {
                onClose();
                onNo?.();
              }}
            >
              <ModalCloseIcon />
            </div>
          </div>
        </div>
      </ScrollContainer>
    </div>
  );
};
