import React from "react";
import Head from "next/head";
import { Header } from "components/aud/base/Header";
import { Footer } from "components/aud/base/Footer";
import { useUser } from "context/userContext";
import { Body } from "components/aud/base/Body";
import { Notice } from "components/aud/ui/Notice";
import { useRouter } from "next/router";
import SmartBanner from "react-smartbanner";
import "react-smartbanner/dist/main.css";
import { IsOpenCreateClassroomModalProvider } from "~/components/aud/providers/IsOpenCreateClassroomModalProvider";

type AudiencePageLayoutProps = React.PropsWithChildren<{}>;
export const AudiencePageLayout: React.FC<AudiencePageLayoutProps> = ({ children }) => {
  const { user } = useUser();
  const router = useRouter();

  const onlyContent = router.query.content_only === "true";

  const needEmailVerification = false;

  const needIDResubmit = user && user.isStudentAge && user.state == "need_resubmit";
  const paymentRequired = !needEmailVerification && user?.stripeInvoiceStatus === "open";
  const hasNotice = needEmailVerification || needIDResubmit || paymentRequired;

  if (user?.state === "need_resubmit" && !(router.pathname === "/settings")) {
    router.replace("/settings");
  }

  return onlyContent ? (
    <>
      <Body onlyContent={onlyContent}>{children}</Body>
    </>
  ) : (
    <>
      <Body withHeader withNotice={hasNotice}>
        <SmartBanner title="NowDo" button="ダウンロード" />
        <Head>
          <link rel="icon" href="/images/favicon.ico" />
          <meta name="format-detection" content="telephone=no" />
        </Head>
        <Notice
          needEmailVerification={needEmailVerification}
          paymentRequired={paymentRequired}
          needIDResubmit={needIDResubmit}
          user={user}
        />
        <IsOpenCreateClassroomModalProvider>
          <Header withNotice={hasNotice} />
          {children}
        </IsOpenCreateClassroomModalProvider>

        <Footer user={user} />
      </Body>
    </>
  );
};
