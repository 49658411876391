import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";
import { authorizationHeader } from "../submodules/http/authorizationHeader";
import { RootState } from "store";
import { getAuthToken } from "~/authtoken";
import { retry } from "./retry";

async function backoff(attempt = 0, maxRetries = 5) {
  const attempts = Math.min(attempt, maxRetries);

  const timeout = ~~((Math.random() + 0.3) * (700 << attempts));
  await new Promise((resolve) => setTimeout((res) => resolve(res), timeout));
}

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API_ENDPOINT,
    prepareHeaders: (headers, api) => {
      const token = getAuthToken({}) || (api.getState() as RootState).auth.fallbackToken;

      if (token) {
        Object.entries(authorizationHeader(token).headers).forEach(([key, value]) => headers.set(key, value));
      }

      return headers;
    },
  }),
  {
    maxRetries: 3,
    backoff,
    // 400番台はretryしません. 以下のPRがマージされたら、自前のretryを廃止します
    // https://github.com/reduxjs/redux-toolkit/pull/2239
    needRetryError: (error) => {
      const status = error.status as number;
      return !(400 <= status && status < 500);
    },
  }
);

export const apiTagTypes = {
  All: "All",
  Auth: "Auth",
  Event: "Event",
  User: "User",
  Follow: "Follow",
  Classroom: "Classroom",
  ClassroomComment: "ClassroomComment",
  Archive: "Archive",
  Category: "Category",
} as const;

export const baseApi = createApi({
  baseQuery,
  tagTypes: Object.values(apiTagTypes),
  endpoints: () => ({}),
  refetchOnReconnect: true,
  refetchOnMountOrArgChange: 300,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
});

export type NowDoEndpointBuilder = Parameters<Parameters<(typeof baseApi)["injectEndpoints"]>[0]["endpoints"]>[0];
