import css from "./Notice.module.scss";
import { useState } from "react";
import { ResendVerificationEmailModal } from "components/ResendVerificationEmailModal";
import { Image } from "~/components/Image";

export const Notice = ({ needEmailVerification, paymentRequired, needIDResubmit, user }) => {
  const [resendModalOpen, setResendModalOpen] = useState(false);

  const handleClickResend = (e) => {
    e.preventDefault();
    setResendModalOpen(true);
  };

  return (
    <>
      {needEmailVerification && (
        <div className={`${css.notice} ${css.blue}`}>
          <Image src="/images/info.png" alt="" width={22.5} height={22.5} />
          <div className={css.inner}>
            <p className={css.text}>
              登録したメールアドレスに送信されたメールをご確認ください
              <button className={css.resend} onClick={handleClickResend}>
                確認メールを
                <br className="_only__sp" />
                再送信する
              </button>
            </p>
          </div>
        </div>
      )}
      {paymentRequired && (
        <div className={`${css.notice} ${css.blue}`}>
          <Image src="/images/info.png" alt="" width={22.5} height={22.5} />
          <div className={css.inner}>
            <p className={css.text}>クレジットカードの決済に失敗しました。カード情報の確認をお願い致します</p>
          </div>
        </div>
      )}
      {/* offline && (
        <div className={`${css.notice} ${css.gray}`}>
          <Image src="/images/info.png" alt="" />
          <div className={css.inner}>
            <p>
              インターネットに接続されていません。ネットワークをご確認ください
            </p>
          </div>
        </div>
      ) */}
      {/* {waitingIDVerification && (
        <div className={`${css.notice} ${css.blue}`}>
          <Image src="/images/info.png" alt="" />
          <div className={css.inner}>
            <p>本人確認書類の審査中です。審査完了までしばらくお待ち下さい。</p>
          </div>
        </div>
      )} */}
      {needIDResubmit && (
        <div className={`${css.notice} ${css.blue}`}>
          <Image src="/images/info.png" alt="" width={22.5} height={22.5} />
          <div className={css.inner}>
            <p>本人確認書類の再提出が必要です。提出後全機能をお使いいただけるようになります。</p>
          </div>
        </div>
      )}
      {user?.fromFirebase?.emailVerified && (
        <ResendVerificationEmailModal open={resendModalOpen} onClose={() => setResendModalOpen(false)} user={user} />
      )}
    </>
  );
};
