import Link, { LinkProps } from "next/link";
import { nowdoApi } from "~/hooks/rtk-query-api";
import { AppStore } from "~/store";
import { CustomPageLink } from "~/types/customPageLink";

export const loadNotificationsData = async (store: AppStore) => {
  await Promise.all([store.dispatch(nowdoApi.endpoints.listMeNotifications.initiate({}))]);
};

export const NotificationsLink: CustomPageLink = ({ aProps = {}, children, ...linkProps }) => {
  const props = {
    ...linkProps,
    ...aProps,
  } as Partial<LinkProps>;

  return (
    <Link
      {...props}
      href="/notifications"
      onMouseEnter={
        () => aProps.target !== "_blank"
        // getするだけで既読が付くので、ここではloadNotificationsDataを実行しません
        // && loadNotificationsData(store)
      }
    >
      {children}
    </Link>
  );
};
