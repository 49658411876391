import { firestore } from "~/firebase";
import { collection, doc, onSnapshot, Timestamp, query, orderBy } from "firebase/firestore";
import { ReactionName } from "~/api";
import { ClassroomUserRole } from "~/types/classroom";
import { apiTagTypes, NowDoEndpointBuilder } from "~/store/baseApi";

export const listFirestoreClassroomUsersBuilder = (build: NowDoEndpointBuilder) => ({
  listFirestoreClassroomUsers: build.query<FirestoreClassroomUser[], { classroomId: string }>({
    providesTags: [apiTagTypes["All"]],
    queryFn: () => ({
      data: [],
    }),
    async onCacheEntryAdded(arg, { updateCachedData, cacheEntryRemoved }) {
      const unsubscribe = onSnapshot(
        query(collection(doc(collection(firestore, "rooms"), arg.classroomId), "users"), orderBy("joinedAt")),
        { includeMetadataChanges: true },
        (snapshot) => {
          const users: FirestoreClassroomUser[] = [];
          snapshot.forEach((u) => users.push(u.data() as FirestoreClassroomUser));
          updateCachedData(() => users);
        }
      );
      await cacheEntryRemoved;
      unsubscribe();
    },
  }),
});

export interface FirestoreClassroomUser {
  avatarUrl: string;
  classroomId: string;
  fullName: string;
  id: string;
  isMuted: boolean;
  isPremium: boolean;
  isProfessional: boolean;
  isSpeaking: boolean;
  joinedAt: Timestamp;
  reactionName: ReactionName;
  role: ClassroomUserRole;
}
