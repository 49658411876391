import { User } from "~/context/userContext";

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

declare global {
  interface Window {
    gtag: any;
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
  window.gtag("config", GA_TRACKING_ID, {
    page_path: url,
  });
};

type Action =
  | "view_event_page"
  | "view_archive_page"
  | "view_top_page"
  | "view_events_page"
  | "view_archives_page"
  | "view_signup_page"
  | "view_subscription_page"
  | "view_campaign_page"
  | "click_event"
  | "click_signup_button"
  | "click_signup_event_button"
  | "click_signup_archive_button"
  | "click_signup_invited_event_button"
  | "click_signup_invited_archive_button"
  | "click_signin_button"
  | "click_signin_event_button"
  | "click_signin_archive_button"
  | "click_signin_invited_event_button"
  | "click_signin_invited_archive_button"
  | "click_join_event_button"
  | "click_purchase_event_button"
  | "click_join_archive_button"
  | "click_purchase_archive_button"
  | "click_guest_ticket_event_button"
  | "click_guest_purchase_event_button"
  | "click_join_invited_archive_button"
  | "click_purchase_invited_archive_button"
  | "click_guest_ticket_archive_button"
  | "click_join_invited_event_button"
  | "click_purchase_invited_event_button"
  | "click_guest_ticket_invited_event_button"
  | "click_guest_purchase_invited_event_button"
  | "click_twitter_share"
  | "click_line_share"
  | "click_facebook_share"
  | "click_discord_button"
  | "click_download_button"
  | "click_appstore_download_button"
  | "click_playstore_download_button"
  | "click_subscription_success"
  | "click_subscription_failed"
  | "click_subscription_cancel_button"
  | "fill_email"
  | "fill_name"
  | "fill_birthday"
  | "fill_password"
  | "click_signup_success"
  | "click_signup_failed"
  // ここからは未使用の actionです
  | "click_open_zoom_button"
  | string;

type EventParams = {
  action: Action;
  category: string;
  label: string;
  value?: number;
};
// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value = 1 }: EventParams) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

let _action = "";

type AnalyticsParams = {
  action: Action;
  label: string;
  user?: User | null;
};
export const Analytics = ({ action, label, user = null }: AnalyticsParams) => {
  let category = "";

  if (_action === action) return;

  if (user?.isSubscribed) {
    category = "JoinEvent";
  } else if (user?.isStudentAge) {
    category = "Subscription";
  } else if (user) {
    category = "PurchaseEvent";
  } else {
    category = "CreateAccount";
  }

  _action = action;

  switch (action) {
    // PV
    case "view_event_page":
      event({ action, category, label });
      break;
    case "view_archive_page":
      event({ action, category, label });
      break;
    case "view_top_page":
      event({ action, category, label });
      break;
    case "view_events_page":
      event({ action, category, label });
      break;
    case "view_archives_page":
      event({ action, category, label });
      break;
    case "view_signup_page":
      event({ action, category, label });
      break;
    case "view_subscription_page":
      event({ action, category, label });
      break;
    case "view_campaign_page":
      event({ action, category, label });
      break;

    // Common
    case "click_event":
      event({ action, category, label });
      break;
    case "click_signup_button":
      event({ action, category, label });
      break;
    case "click_signup_event_button":
      event({ action, category, label });
      break;
    case "click_signup_archive_button":
      event({ action, category, label });
      break;
    case "click_signup_invited_event_button":
      event({ action, category, label });
      break;
    case "click_signup_invited_archive_button":
      event({ action, category, label });
      break;
    case "click_signin_button":
      event({ action, category, label });
      break;
    case "click_signin_event_button":
      event({ action, category, label });
      break;
    case "click_signin_archive_button":
      event({ action, category, label });
      break;
    case "click_signin_invited_event_button":
      event({ action, category, label });
      break;
    case "click_signin_invited_archive_button":
      event({ action, category, label });
      break;

    // Event/[id]
    //// User
    case "click_join_event_button":
      event({ action, category, label });
      break;
    case "click_purchase_event_button":
      event({ action, category, label });
      break;

    case "click_join_archive_button":
      event({ action, category, label });
      break;
    case "click_purchase_archive_button":
      event({ action, category, label });
      break;

    //// Guest
    case "click_guest_ticket_event_button":
      event({ action, category, label });
      break;
    case "click_guest_purchase_event_button":
      event({ action, category, label });
      break;

    case "click_join_invited_archive_button":
      event({ action, category, label });
      break;
    case "click_purchase_invited_archive_button":
      event({ action, category, label });
      break;

    case "click_guest_ticket_archive_button":
      event({ action, category, label });
      break;

    //// Invited
    case "click_join_invited_event_button":
      event({ action, category, label });
      break;
    case "click_purchase_invited_event_button":
      event({ action, category, label });
      break;

    case "click_guest_ticket_invited_event_button":
      event({ action, category, label });
      break;
    case "click_guest_purchase_invited_event_button":
      event({ action, category, label });
      break;

    //// Share
    case "click_twitter_share":
      event({ action, category, label });
      break;
    case "click_line_share":
      event({ action, category, label });
      break;
    case "click_facebook_share":
      event({ action, category, label });
      break;

    //// Other
    case "click_discord_button":
      event({ action, category, label });
      break;

    //// Other
    case "click_download_button":
      event({ action, category, label });
      break;
    case "click_appstore_download_button":
      event({ action, category, label });
      break;
    case "click_playstore_download_button":
      event({ action, category, label });
      break;

    // Subscription
    case "click_subscription_success":
      event({ action, category, label });
      break;
    case "click_subscription_failed":
      event({ action, category, label });
      break;
    case "click_subscription_cancel_button":
      event({ action, category, label });
      break;

    // SignUp
    case "fill_email":
      event({ action, category, label });
      break;
    case "fill_name":
      event({ action, category, label });
      break;
    case "fill_birthday":
      event({ action, category, label });
      break;
    case "fill_password":
      event({ action, category, label });
      break;

    case "click_signup_success":
      event({ action, category, label });
      break;
    case "click_signup_failed":
      event({ action, category, label });
      break;
  }
};
