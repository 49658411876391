import { User } from "~/context/userContext";
import Link from "next/link";
import css from "./Footer.module.scss";
import { Analytics } from "components/GoogleAnalytics";
import { Image } from "~/components/Image";

type FooterProps = { user: User | null };
export const Footer: React.FC<FooterProps> = ({ user }) => (
  <footer className={css.footer}>
    <ul className={css.links}>
      <li>
        <a href="https://nowdo.jp" target="_blank" rel="noreferrer">
          運営会社
        </a>
      </li>
      <li>
        <Link href="/aud/terms_of_use">利用規約</Link>
      </li>
      <li>
        <Link href="/privacy_policy">プライバシーポリシー</Link>
      </li>
      <li>
        <Link href="/law">特定商取引法に基づく表示</Link>
      </li>
      <li>
        <Link href="/brand_assets">ロゴの利用について</Link>
      </li>
    </ul>

    <div className={css.buttons}>
      <a
        href="https://itunes.apple.com/jp/app/id1448978183"
        onClick={() => {
          Analytics({
            action: "click_appstore_download_button",
            label: "Footer",
            user,
          });
        }}
        target="_blank"
        // TODO: className={css.storebanner}
        className={`${css.storebanner}`}
        rel="noreferrer"
      >
        <Image
          src="/images/appstore.png"
          className={css.icon}
          width={135}
          height={44}
          alt="Download nowdo app from itunes store"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=net.nowdo"
        onClick={() => {
          Analytics({
            action: "click_playstore_download_button",
            label: "Footer",
            user,
          });
        }}
        target="_blank"
        className={`${css.storebanner}`}
        rel="noreferrer"
      >
        <Image
          src="/images/playstore.png"
          className={css.icon}
          width={148}
          height={44}
          alt="Download NowDo app from google play store"
        />
      </a>
      <a
        href="https://twitter.com/nowdoofficial"
        target="_blank"
        className={`${css.twitter} ${css.withIcon}`}
        rel="noreferrer"
      >
        <div className={css.icon}>
          <Image src="/images/icon__twitter.svg" width={40} height={40} alt="Go to NowDo official twitter" />
        </div>
        <span>NowDo公式Twitter</span>
        <b>@nowdoOfficial</b>
      </a>
      <a href="https://help.nowdo.in" target="_blank" className={`${css.helpcenter} ${css.withIcon}`} rel="noreferrer">
        <div className={css.icon}>
          <Image src="/images/icon__support.svg" width={40} height={40} alt="Go to help center" />
        </div>
        <span>公式ヘルプセンター</span>
        <b>お問い合わせ</b>
      </a>
    </div>
  </footer>
);
