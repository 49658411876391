import React, { useContext, useState } from "react";

const ctx = React.createContext<{
  isOpenCreateClassroom: boolean;
  setIsOpenCreateClassroom: (isOpen: boolean) => void;
} | null>(null);

export function useIsOpenCreateClassroomModal() {
  const context = useContext(ctx);
  if (context === null) {
    throw Error("required IsOpenCreateClassroomModalProvider");
  }
  return context;
}

export const IsOpenCreateClassroomModalProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpenCreateClassroom, setIsOpenCreateClassroom] = useState(false);

  return (
    <ctx.Provider
      value={{
        isOpenCreateClassroom,
        setIsOpenCreateClassroom,
      }}
    >
      {children}
    </ctx.Provider>
  );
};
