import Link, { LinkProps } from "next/link";
import { useStore } from "~/hooks/useStore";
import { nowdoApi } from "~/hooks/rtk-query-api";
import { AppStore } from "~/store";
import { CustomPageLink } from "~/types/customPageLink";

export const loadArchivesPageData = async (store: AppStore) => {
  await Promise.all([
    store.dispatch(nowdoApi.endpoints.listCategories.initiate()),
    store.dispatch(nowdoApi.endpoints.listArchives.initiate({})),
    store.dispatch(nowdoApi.endpoints.listSubscriptionPlans.initiate()),
  ]);
};

export const ArchivesPageLink: CustomPageLink = ({ aProps = {}, children, ...linkProps }) => {
  const store = useStore();

  const props = {
    ...linkProps,
    ...aProps,
  } as Partial<LinkProps>;

  return (
    <Link {...props} href="/archives" onMouseEnter={() => aProps.target !== "_blank" && loadArchivesPageData(store)}>
      {children}
    </Link>
  );
};
